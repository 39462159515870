import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ResourceIcon } from '../../../../components/resources/ResourceIcon';
import { useGetConnectorCompatibilityStatusesQuery } from '../../../../store/api/connector.api';
import { connectorCompatibilityStatusSelector } from '../../../../store/selectors/connector.selectors';
import { FlexBox } from '../../../../styled/flex/FlexBox';
import { FlexBoxWithPointer } from '../../../../styled/flex/FlexBoxWithPointer';
import { FlexCol } from '../../../../styled/flex/FlexCol';
import { FlexRow } from '../../../../styled/flex/FlexRow';
import { StyledSubTextLight } from '../../../../styled/text/Text';
import { theme } from '../../../../theme';
import {
  IConnectorCompatibilityStatusParams,
  ResourceType,
} from '../../../../types/connector.types';
import { getResourceIconColors } from '../../../../utils/sessions/getResourceIconColors';

const MESSAGES = defineMessages({
  notCompatible: {
    id: 'select_connector_list.notCompatible',
    defaultMessage: 'Not compatible',
  },
  occupied: {
    id: 'select_connector_list.occupied',
    defaultMessage: 'Occupied',
  },
});

interface IProps {
  connectorCompatibilityStatusParams: IConnectorCompatibilityStatusParams;
  resourceType: ResourceType;
  onSelectConnector: (connectorId: string) => void;
  selectedConnectorId?: string;
}

export const SelectConnectorList = ({
  connectorCompatibilityStatusParams,
  onSelectConnector,
  resourceType,
  selectedConnectorId,
}: IProps) => {
  const intl = useIntl();

  const [isHovered, setIsHovered] = useState<number | undefined>();

  useGetConnectorCompatibilityStatusesQuery(connectorCompatibilityStatusParams);

  const connectorCompatibilityStatuses = useSelector(
    connectorCompatibilityStatusSelector(connectorCompatibilityStatusParams),
  );

  const handleSelectConnector = (
    connectorId: string,
    isCompatible: boolean,
    occupied: boolean,
  ) => {
    if (!isCompatible || occupied) {
      return undefined;
    }
    return onSelectConnector(connectorId);
  };

  return (
    <FlexRow justifyContent="space-around" mx={theme.spacing.medium}>
      {connectorCompatibilityStatuses
        ?.filter((connector) => connector.active)
        .map((connector, index) => {
          const {
            backgroundColor,
            iconFill,
            iconStroke,
            innerCircleStroke,
            outerCircleStroke,
          } = getResourceIconColors(
            connector.connectorId === selectedConnectorId,
            connector.compatible,
            connector.occupied,
            index === isHovered,
          );

          return (
            <FlexCol
              alignItems="center"
              key={connector.connectorId}
              onClick={() =>
                handleSelectConnector(
                  connector.connectorId,
                  connector.compatible,
                  connector.occupied,
                )
              }
            >
              <FlexBoxWithPointer
                width="60px"
                height="60px"
                backgroundColor={backgroundColor}
                border={`1px solid ${outerCircleStroke}`}
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(undefined)}
              >
                <FlexBox
                  backgroundColor={backgroundColor}
                  border={`1px solid ${innerCircleStroke}`}
                  borderRadius="50%"
                  width="40px"
                  height="40px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ResourceIcon
                    resourceType={resourceType}
                    connectorNumber={{
                      connectorIndex: connector.index,
                      color: iconFill,
                    }}
                    fill={iconFill}
                    stroke={
                      resourceType === ResourceType.POWER ? iconStroke : ''
                    }
                    width="10"
                    height="20px"
                  />
                </FlexBox>
              </FlexBoxWithPointer>
              {!connector.compatible && (
                <StyledSubTextLight>
                  {intl.formatMessage(MESSAGES.notCompatible)}
                </StyledSubTextLight>
              )}
              {connector.occupied && (
                <StyledSubTextLight>
                  {intl.formatMessage(MESSAGES.occupied)}
                </StyledSubTextLight>
              )}
            </FlexCol>
          );
        })}
    </FlexRow>
  );
};
