import { Rule } from 'antd/lib/form';
import { IntlShape } from 'react-intl';

import { FORM_VALIDATION_MESSAGES } from '../../consts/form.consts';

export const getFixedLengthRule = (
  label: string,
  fixedLength: number,
  intl: IntlShape,
): Rule => ({
  len: fixedLength,
  message: intl.formatMessage(FORM_VALIDATION_MESSAGES.fixedLength, {
    label,
    fixedLength,
  }),
});
