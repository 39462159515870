import { Form } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { userFilterByKeySelector } from '../../../store/selectors/filters.selectors';
import { setUserFilter } from '../../../store/slices/filters.slice';
import { updateRequestedTablePage } from '../../../store/slices/tableMetaInfo.slice';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledFormItem } from '../../../styled/form/StyledFormItem';
import { StyledSearch } from '../../../styled/form/StyledSearch';
import { FilterKey, UserFilterKey } from '../../../types/filters.types';
import { Table } from '../../../types/table.types';
import { UserFilter } from '../../../types/user.types';
import { FilterElements } from '../../../components/filters/FilterElements';

const MESSAGES = defineMessages({
  search: {
    id: 'users_table_filter.search',
    defaultMessage: 'Search',
  },
  [UserFilter.EMAIL]: {
    id: 'users_table_filter.email',
    defaultMessage: 'Email',
  },
  [UserFilter.FIRST_NAME]: {
    id: 'users_table_filter.firstName',
    defaultMessage: 'First Name',
  },
  [UserFilter.LAST_NAME]: {
    id: 'users_table_filter.lastName',
    defaultMessage: 'Last Name',
  },
  [UserFilter.PHONE_NUMBER]: {
    id: 'users_table_filter.phoneNumber',
    defaultMessage: 'Phone Number',
  },
});

export const UsersTableFilter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const lastNameFilterValue = useSelector(
    userFilterByKeySelector(UserFilterKey.lastName),
  );
  const firstNameFilterValue = useSelector(
    userFilterByKeySelector(UserFilterKey.firstName),
  );
  const emailFilterValue = useSelector(
    userFilterByKeySelector(UserFilterKey.email),
  );
  const phoneNumberFilterValue = useSelector(
    userFilterByKeySelector(UserFilterKey.phoneNumber),
  );

  const handleChangeFilter = (key: FilterKey, value: string | number) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.VESSELS_TABLE,
        value: 0,
      }),
    );
    dispatch(
      setUserFilter({
        key,
        value,
      }),
    );
  };

  return (
    <PrimaryWrapperBox>
      <Form form={form} layout="inline">
        <FilterElements
          title={intl.formatMessage(MESSAGES[UserFilter.LAST_NAME])}
        >
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(UserFilterKey.lastName, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(UserFilterKey.lastName, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={lastNameFilterValue}
            data-testid={`search-${UserFilterKey.lastName}`}
          />
        </FilterElements>

        <FilterElements
          title={intl.formatMessage(MESSAGES[UserFilter.FIRST_NAME])}
        >
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(UserFilterKey.firstName, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(UserFilterKey.firstName, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={firstNameFilterValue}
            data-testid={`search-${UserFilterKey.firstName}`}
          />
        </FilterElements>

        <FilterElements title={intl.formatMessage(MESSAGES[UserFilter.EMAIL])}>
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(UserFilterKey.email, ev.target.value)
            }
            onSearch={(value) => handleChangeFilter(UserFilterKey.email, value)}
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={emailFilterValue}
            data-testid={`search-${UserFilterKey.email}`}
          />
        </FilterElements>

        <FilterElements
          title={intl.formatMessage(MESSAGES[UserFilter.PHONE_NUMBER])}
        >
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(UserFilterKey.phoneNumber, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(UserFilterKey.phoneNumber, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={phoneNumberFilterValue}
            data-testid={`search-${UserFilterKey.phoneNumber}`}
          />
        </FilterElements>
      </Form>
    </PrimaryWrapperBox>
  );
};
