import { groupBy } from 'lodash';
import { Point } from 'chart.js';
import {
  ICurrentDataPoint,
  IPowerDataPoint,
  TechmetrysGraphData,
} from '../../types/session.types';

function generateLabelColor(key: string): string {
  switch (key) {
    case 'LINE_1':
      return 'rgb(207, 176, 99)';
    case 'LINE_2':
      return 'rgb(255, 77, 79)';
    case 'LINE_3':
      return 'rgb(73, 81, 105)';
    case 'power':
      return 'rgb(2, 162, 79)';
    default:
      return 'rgb(73, 81, 105)';
  }
}

function generateLabel(key: string): string {
  switch (key) {
    case 'LINE_1':
      return 'Line 1';
    case 'LINE_2':
      return 'Line 2';
    case 'LINE_3':
      return 'Line 3';
    case 'power':
      return 'Power';
    default:
      return 'Unknown';
  }
}

const returnDataForChart = (rawData: any, hasLines: boolean) => {
  const datasets: TechmetrysGraphData[] = [];

  let groupedByLine = groupBy(rawData, (obj) =>
    hasLines ? obj.line : obj.power,
  );
  let lines = Object.keys(groupedByLine);

  if (!hasLines) {
    lines = ['power'];
    groupedByLine = {
      power: rawData,
    };
  }

  lines.forEach((line) => {
    const label = generateLabel(line);
    const color = generateLabelColor(line);
    datasets.push({
      backgroundColor: color,
      borderColor: color,
      data: groupedByLine[line]
        .map((o) => {
          return {
            x: o.timestamp,
            y: (o.current || o.power) / 1000,
          } as Point;
        })
        .sort((a, b) => a.x - b.x),
      label,
      tension: 0.4,
    });
  });
  return datasets;
};

export const formatAndSortUsageGraphCurrentDataPoints = (
  currentDataPoints: ICurrentDataPoint[] | undefined,
): TechmetrysGraphData[] => returnDataForChart(currentDataPoints, true);

export const formatAndSortUsageGraphPowerDataPoints = (
  powerDataPoints: IPowerDataPoint[] | undefined,
): TechmetrysGraphData[] => returnDataForChart(powerDataPoints, false);
