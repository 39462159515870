import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  useNavigate,
  useParams,
  useMatch,
  useLocation,
} from 'react-router-dom';
import moment from 'moment';

import { hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { UserPermission } from '../../../types/user.types';
import { AppLayout } from '../../../AppLayout';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { WhiteBox } from '../../../components/layout/boxes/WhiteBox';
import { TaskDetails } from './TaskDetails';
import { MaintenanceProblemType } from '../../../types/maintenance.types';
import { useGetAuthorizedBoxByIdQuery } from '../../../store/api/boxes.api';
import { TaskTabs } from '../../../types/tab.types';
import { MaintenanceTaskTabs } from '../../../components/navigation/maintenance/tabs/TaskTabs';
import { appUrls } from '../../../config/url.constants';
import TaskLogs from './TaskLogs';

const MESSAGES = defineMessages({
  editHeading: {
    id: 'maintenance.heading',
    defaultMessage: 'Edit task',
  },
  logsHeading: {
    id: 'maintenance.heading',
    defaultMessage: 'Task logs',
  },
});

interface IProps {
  activeTab: TaskTabs;
}

export const TaskEdit = ({ activeTab }: IProps) => {
  const [currentTab, setCurrentTab] = useState(activeTab);

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const { task } = useParams() as { task: string };
  
  const editRoute = appUrls.maintenance.edit(task);
  const logsRoute = appUrls.maintenance.logs(task);

  const matchEdit = useMatch(editRoute);
  const matchLogs = useMatch(logsRoute);

  const { data, isLoading } = useGetAuthorizedBoxByIdQuery(
    '3526086d-24da-4def-a141-7be2daf30ec9',
  );

  useEffect(() => {
    if (matchEdit) {
      setCurrentTab(TaskTabs.EDIT);
    }
    if (matchLogs) {
      setCurrentTab(TaskTabs.LOGS);
    }
  }, [matchEdit, matchLogs, location]);

  // TO DO: call endpoint for retrieving the task data

  const editTask = {
    box: data,
    connector: '2769629f-edcc-4cb8-b1ae-6660a0f8086c',
    problemType: MaintenanceProblemType.CONNECTOR,
    description: 'This is a problem description',
    priority: 2,
    targetDate: moment('12-12-2024', 'DD-MM-YYYY'),
    comment: 'this is a task comment',
    status: 1,
    assignee: '5b8dea39-5b6c-4737-a715-073ba872a3a4',
  };

  const canCreateTask = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_TASK),
  );
  // TO DO: check if user has permission to edit the task

  const handleTabClick = (tabPane: TaskTabs) => {
    if (tabPane === TaskTabs.EDIT) {
      navigate(editRoute);
    } else if (tabPane === TaskTabs.LOGS) {
      navigate(logsRoute);
    }
  };

  return (
    <AppLayout isAllowed>
      <StyledBox p="20px">
        <MaintenanceTaskTabs
          selectedTab={currentTab}
          onTabClick={handleTabClick}
        />
        {matchEdit && (
          <>
            <StyledHeading variant="h1">
              {intl.formatMessage(MESSAGES.editHeading)}
            </StyledHeading>
            <WhiteBox width="100%">
              {!isLoading && <TaskDetails action="edit" task={editTask} />}
            </WhiteBox>
          </>
        )}
        {matchLogs && (
          <>
            <StyledHeading variant="h1">
              {intl.formatMessage(MESSAGES.logsHeading)}
            </StyledHeading>
            <WhiteBox width="100%">
              <TaskLogs />
            </WhiteBox>
          </>
        )}
      </StyledBox>
    </AppLayout>
  );
};
