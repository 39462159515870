import React from 'react';
import { ConnectorStatusWithNumber } from '../../../components/connectors/ConnectorStatusWithNumber';
import { SubscribeToTags } from './SubscribeToTags';
import { IConnectorBox } from '../../../types/connector.types';
import { getBoxConnectivityStatus } from '../../../utils/boxes/getBoxConnectivityStatus';

interface IProps {
  boxId: string;
  connector: IConnectorBox;
  connectorSymbolStartValue: string | undefined;
  connectorWebStatusValue: string | undefined;
}

export const BoxesTableConnectors = ({
  boxId,
  connector,
  connectorSymbolStartValue,
  connectorWebStatusValue,
}: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);

  return (
    <div style={{ opacity: boxIsOffline ? '.3' : 1 }}>
      <SubscribeToTags connector={connector} />
      <ConnectorStatusWithNumber
        connectorIndex={connector.index}
        connectorWebStatusValue={connectorWebStatusValue}
        connectorSymbolStartValue={connectorSymbolStartValue}
      />
    </div>
  );
};
