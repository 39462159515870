import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { appUrls } from '../../../config/url.constants';
import {
  useDeleteVesselMutation,
  useGetVesselsQuery,
} from '../../../store/api/vessels.api';
import { vesselFiltersByKeysSelector } from '../../../store/selectors/filters.selectors';
import { requestedTablePageSelector } from '../../../store/selectors/tableMetaInfo.selectors';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { FlexRow } from '../../../styled/flex/FlexRow';
import { theme } from '../../../theme';
import {
  IPagedVesselFilters,
  IVesselFilters,
  VesselFilterKey,
} from '../../../types/filters.types';
import { Table } from '../../../types/table.types';
import { ICurrentUser, UserPermission } from '../../../types/user.types';
import { checkIfUserHasPermission } from '../../../utils/users/checkIfUserHasPermission';
import { VesselsTable } from './VesselsTable';
import { VesselsTableFilter } from './VesselsTableFilter';

const MESSAGES = defineMessages({
  addVessel: {
    id: 'vessels_table_with_filter.addVessel',
    defaultMessage: 'Add new vessel',
  },
  deleteVessels: {
    id: 'vessels_table_with_filter.deleteVessels',
    defaultMessage: 'Delete vessel(s)',
  },
  warningDeleteVessels: {
    id: 'vessels_table_with_filter.warningDeleteVessels',
    defaultMessage: 'Are you sure you want to delete these vessels?',
  },
  confirmDeleteVessels: {
    id: 'vessels_table_with_filter.confirmLogOutConfirmButton',
    defaultMessage: 'Confirm Delete',
  },
});

interface IProps {
  currentUser: ICurrentUser;
}

export const VesselsTableWithFilter = ({ currentUser }: IProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [canManageVessels, setCanManageVessels] = useState(
    checkIfUserHasPermission(currentUser, UserPermission.MANAGE_VESSELS),
  );
  const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>([]);

  const requestedSessionTablePage = useSelector(
    requestedTablePageSelector(Table.VESSELS_TABLE),
  );

  useEffect(() => {
    setCanManageVessels(
      checkIfUserHasPermission(currentUser, UserPermission.MANAGE_VESSELS),
    );
  }, [currentUser]);

  const vesselFilterKeys = [
    VesselFilterKey.name,
    VesselFilterKey.eniNumber,
    VesselFilterKey.type,
  ];
  const vesselFilterValues = useSelector(
    vesselFiltersByKeysSelector(vesselFilterKeys),
  ) as IVesselFilters | undefined;

  const requestedVesselFilters: IPagedVesselFilters = {
    ...vesselFilterValues,
    page: requestedSessionTablePage,
    sort: ['name'],
  };

  useGetVesselsQuery(requestedVesselFilters);
  const [deleteVessel] = useDeleteVesselMutation();

  const handleAddNewVesselClick = () => {
    navigate(appUrls.vessels.new);
  };

  const handleDeleteVesselsClick = () => {
    deleteVessel({ vesselIds: selectedVesselIds });
  };

  return (
    <>
      <VesselsTableFilter />
      {canManageVessels && (
        <FlexRow mt={theme.spacing.medium} mb={theme.spacing.medium}>
          <StyledButton
            variant="primary"
            width={theme.input.width.fitContent}
            onClick={handleAddNewVesselClick}
            data-testid="btn-add-vessel"
          >
            <PlusOutlined />
            {intl.formatMessage(MESSAGES.addVessel)}
          </StyledButton>
          <Popconfirm
            title={intl.formatMessage(MESSAGES.warningDeleteVessels)}
            okText={intl.formatMessage(MESSAGES.confirmDeleteVessels)}
            onConfirm={handleDeleteVesselsClick}
            placement="right"
          >
            <StyledButton
              disabled={!selectedVesselIds.length}
              variant="danger"
              width={theme.input.width.fitContent}
              ml={theme.spacing.medium}
              data-testid="btn-delete-vessel"
            >
              <MinusOutlined />
              {intl.formatMessage(MESSAGES.deleteVessels)}
            </StyledButton>
          </Popconfirm>
        </FlexRow>
      )}
      <PrimaryWrapperBox>
        <VesselsTable
          canManageVessels={canManageVessels}
          onSelectVessel={setSelectedVesselIds}
          requestedVesselFilters={requestedVesselFilters}
        />
      </PrimaryWrapperBox>
    </>
  );
};
