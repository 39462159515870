import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FilterKey,
  IAssetFilters,
  IPagedAssetLogFilters,
  ISessionFilters,
  IUserFilters,
  IVesselFilters,
} from '../../types/filters.types';

export interface IFilterPayload {
  key: FilterKey;
  value?: string | number | string[];
}

const boxesFiltersSlice = createSlice({
  name: 'boxesFilters',
  initialState: {} as IAssetFilters,
  reducers: {
    setAssetFilter: (
      state: IAssetFilters,
      { payload }: PayloadAction<IFilterPayload>,
    ) => {
      return payload ? { ...state, [payload.key]: payload.value } : state;
    },
    resetAssetFilters: () => {},
  },
})

const sessionFiltersSlice = createSlice({
  name: 'sessionFilters',
  initialState: {} as ISessionFilters,
  reducers: {
    setSessionFilter: (
      state: ISessionFilters,
      { payload }: PayloadAction<IFilterPayload>,
    ) => {
      return payload ? { ...state, [payload.key]: payload.value } : state;
    },
    resetSessionFilters: () => {},
  },
});

const userFiltersSlice = createSlice({
  name: 'userFilters',
  initialState: {} as IUserFilters,
  reducers: {
    setUserFilter: (
      state: IUserFilters,
      { payload }: PayloadAction<IFilterPayload>,
    ) => {
      return payload ? { ...state, [payload.key]: payload.value } : state;
    },
    resetUserFilters: () => {},
  },
});

const assetLogFiltersSlice = createSlice({
  name: 'assetLogFilters',
  initialState: {} as IPagedAssetLogFilters,
  reducers: {
    setAssetLogFilter: (
      state: IPagedAssetLogFilters,
      { payload }: PayloadAction<IFilterPayload>,
    ) => {
      console.log('NEW FILTER', { ...state, [payload.key]: payload.value });
      return payload ? { ...state, [payload.key]: payload.value } : state;
    },
    resetAssetLogFilters: () => {},
  },
});


const vesselFiltersSlice = createSlice({
  name: 'vesselFilters',
  initialState: {} as IVesselFilters,
  reducers: {
    setVesselFilter: (
      state: IVesselFilters,
      { payload }: PayloadAction<IFilterPayload>,
    ) => {
      return payload ? { ...state, [payload.key]: payload.value } : state;
    },
  },
});

export const {
  resetAssetFilters,
  resetSessionFilters,
  setAssetFilter,
  setAssetLogFilter,
  setSessionFilter,
  setUserFilter,
  setVesselFilter,
} = {
  ...assetLogFiltersSlice.actions,
  ...boxesFiltersSlice.actions,
  ...sessionFiltersSlice.actions,
  ...userFiltersSlice.actions,
  ...vesselFiltersSlice.actions,
};

export const filtersReducer = combineReducers({
  assetLogFilters: assetLogFiltersSlice.reducer,
  boxFilters: boxesFiltersSlice.reducer,
  sessionFilters: sessionFiltersSlice.reducer,
  userFilters: userFiltersSlice.reducer,
  vesselFilters: vesselFiltersSlice.reducer,
});
