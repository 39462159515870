import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';

import { NotificationAutoClose } from './components/feedback/NotificationAutoClose';
import { ILanguageHandle, useLanguage } from './hooks/useLanguage';
import { AppRoutes } from './routes/AppRoutes';
import { UserActions } from './store/actions/user.actions';
import languageCode from './types/language.types';
import { getAccessTokenLocalStorage } from './utils/api/localStorage.utils';
import { wsClient } from './utils/ws/WsClient';

export const AuthenticatedApp = () => {
  const languageHook: ILanguageHandle = useLanguage(); // todo: store language choice in state
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenLocalStorage = getAccessTokenLocalStorage();
    if (tokenLocalStorage) {
      dispatch(UserActions.setToken(tokenLocalStorage));
      wsClient.init(tokenLocalStorage);
    }
  }, []);

  return (
    <IntlProvider
      defaultLocale={languageCode.EN}
      locale={languageHook.resource.language}
      messages={languageHook.resource.messages}
    >
      <NotificationAutoClose />
      <AppRoutes />
    </IntlProvider>
  );
};
