import { SendOutlined } from '@ant-design/icons';
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/query';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { toasts } from '../../../../../components/feedback/toasts';
import { usePostAssetCommandMutation } from '../../../../../store/api/assets.api';
import { StyledButton } from '../../../../../styled/buttons/StyledButton';
import { StyledInput } from '../../../../../styled/form/StyledInput';
import { StyledSelect } from '../../../../../styled/form/StyledSelect';
import { theme } from '../../../../../theme';
import { IAssetCommand } from '../../../../../types/asset.types';
import { TagActionValue, TagValueType } from '../../../../../types/tag.types';

const MESSAGES = defineMessages({
  actionAccepted: {
    id: 'send_action_form.actionAccepted',
    defaultMessage: 'Action has been submitted.',
  },
  actionNotAccepted: {
    id: 'send_action_form.actionNotAccepted',
    defaultMessage: 'Action was not accepted.',
  },
  formActionInput: {
    id: 'send_action_form.formActionInput',
    defaultMessage: 'Action input',
  },
  submitForm: {
    id: 'send_action_form.submitForm',
    defaultMessage: 'Send command',
  },
  unknownError: {
    id: 'send_command_form.unknownError',
    defaultMessage: 'An unknown error has occurred.',
  },
});

interface IProps {
  assetId: string;
  tagName: string;
  tagValueType: TagValueType;
}

const tagActionOptions = [
  {
    label: 'false',
    value: TagActionValue.FALSE,
  },
  {
    label: 'true',
    value: TagActionValue.TRUE,
  },
];

export const SendCommandForm = ({ assetId, tagName, tagValueType }: IProps) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const [postCommand, result] = usePostAssetCommandMutation();

  useEffect(() => {
    if (result.status === QueryStatus.fulfilled) {
      toasts.success({
        autoHide: true,
        message: intl.formatMessage(MESSAGES.actionAccepted),
      });
    }
    if (result.status === QueryStatus.rejected) {
      toasts.error({
        autoHide: true,
        message: intl.formatMessage(MESSAGES.actionNotAccepted),
        // error returned by RTK Query could be from the server (FetchBaseQueryError)
        // or it could be any error thrown by code that is written (SerializedError, for ex. in query, queryFn,...)
        // since error could be of different shapes, we need to make sure it's a FetchBaseQueryError by checking
        // if data exists in the error response
        description: () => {
          if ((result.error as FetchBaseQueryError).data) {
            return (result.error as FetchBaseQueryError).data;
          }
          return intl.formatMessage(MESSAGES.unknownError);
        },
      });
    }
  }, [result]);

  const handleFinish = ({ input }: { input: string }) => {
    const command: IAssetCommand = {
      tag: tagName,
      input,
    };
    postCommand({
      assetId,
      command,
    });
    form.resetFields();
  };

  const handleFail = (errorInfo: unknown) => {
    toasts.error({
      autoHide: false,
      message: errorInfo,
    });
  };

  return (
    <Form
      form={form}
      name="SendCommandForm"
      layout="inline"
      onFinish={handleFinish}
      onFinishFailed={handleFail}
      data-testid="form-send-command"
    >
      {tagValueType === TagValueType.REAL ? (
        <Form.Item name="input">
          <StyledInput
            placeholder={intl.formatMessage(MESSAGES.formActionInput)}
            data-testid="input-send-command-input"
          />
        </Form.Item>
      ) : (
        <Form.Item name="input">
          <StyledSelect
            options={tagActionOptions}
            placeholder={intl.formatMessage(MESSAGES.formActionInput)}
            data-testid="select-send-command-input"
          />
        </Form.Item>
      )}
      <Form.Item>
        <StyledButton
          variant="primary"
          htmlType="submit"
          m={theme.spacing.xSmall}
          icon={<SendOutlined />}
          data-testid="btn-send-command-form-submit"
        >
          {intl.formatMessage(MESSAGES.submitForm)}
        </StyledButton>
      </Form.Item>
    </Form>
  );
};
