import { parse as parseContentDispositionHeader } from 'content-disposition-header';
import FileSaver from 'file-saver';

import { TOAST_MESSAGES } from '../../components/feedback/NotificationAutoClose';
import { IPagedSessionFilters } from '../../types/filters.types';
import {
  INewSessionPayload,
  ISession,
  ISessionDetail,
  ISessionEvent,
  ISessionsResponse,
  ISessionStatusChange,
  IUsageUpdate,
  SessionStatus,
} from '../../types/session.types';
import { wsClient } from '../../utils/ws/WsClient';
import { setErrorMessage } from '../slices/layout.slice';
import { addCancelledSession } from '../slices/session.slice';
import { baseApi, RtkTagType } from './base.api';

export const sessionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSessions: builder.query<ISessionsResponse, IPagedSessionFilters>({
      query: (params) => ({
        url: '/sessions',
        params,
      }),
      providesTags: [{ type: RtkTagType.SESSIONS }],
    }),
    getSessionsCsv: builder.query<void, IPagedSessionFilters>({
      query: (params) => ({
        url: '/sessions',
        params: {
          ...params,
          active: false,
          page: 0,
          size: 2 ** 31 - 1, // get all sessions in a single page, this number is the max value for an int in Java
        },
        headers: {
          Accept: 'text/csv',
        },
        cache: 'no-cache',
        responseHandler: async (response) => {
          response
            .blob()
            .then((data) => {
              const { filename } = parseContentDispositionHeader(
                response.headers.get('Content-Disposition') || 'sessions.csv',
              ).parameters;
              FileSaver.saveAs(data, filename);
            })
            .catch((e) => {
              setErrorMessage({
                message: TOAST_MESSAGES.errorSessionExport,
                description: e.message,
              });
            });
        },
      }),
    }),
    getSessionById: builder.query<ISessionDetail, string>({
      query: (sessionId) => `sessions/${sessionId}`,
      providesTags: (result, error, id) => [{ type: RtkTagType.SESSION, id }],
      async onCacheEntryAdded(
        sessionId,
        { cacheDataLoaded, cacheEntryRemoved, dispatch, updateCachedData },
      ) {
        await cacheDataLoaded;
        const usageSubscription = await wsClient.subscribe(
          `/topic/sessions/${sessionId}/usage`,
          (usageUpdate: IUsageUpdate) => {
            updateCachedData((session) => {
              const updatedSession = session;
              updatedSession.consumption.total = usageUpdate.consumption;
              updatedSession.consumption.meterStart = usageUpdate.meterStart;
              updatedSession.consumption.meterReadout =
                usageUpdate.meterReadout;
              updatedSession.metrics.maxCurrentL1 = usageUpdate.maxCurrentL1;
              updatedSession.metrics.maxCurrentL2 = usageUpdate.maxCurrentL2;
              updatedSession.metrics.maxCurrentL3 = usageUpdate.maxCurrentL3;
              if (
                updatedSession.metrics.powerUsageOverTime.length > 0 &&
                updatedSession.metrics.powerUsageOverTime[
                  updatedSession.metrics.powerUsageOverTime.length - 1
                ].timestamp !== usageUpdate.mostRecentPowerDataPoint.timestamp
              ) {
                session.metrics.powerUsageOverTime.push(
                  usageUpdate.mostRecentPowerDataPoint,
                );
              }
              if (
                updatedSession.metrics.currentOverTime.length > 0 &&
                updatedSession.metrics.currentOverTime[
                  updatedSession.metrics.currentOverTime.length - 1
                ].timestamp !== usageUpdate.mostRecentCurrentDataPoint.timestamp
              ) {
                session.metrics.currentOverTime.push(
                  usageUpdate.mostRecentCurrentDataPoint,
                );
              }
            });
          },
        );
        const eventSubscription = await wsClient.subscribe(
          `/topic/sessions/${sessionId}/events`,
          (newEvent: ISessionEvent) => {
            updateCachedData((session) => {
              session.events.push(newEvent);
            });
          },
        );
        const sessionStatusSubscription = await wsClient.subscribe(
          `/topic/sessions/${sessionId}/status`,
          (statusChange: ISessionStatusChange) => {
            updateCachedData((session) => {
              const updatedSession = session;
              switch (statusChange.status) {
                case SessionStatus.STARTED:
                  updatedSession.startedAt = statusChange.changedAt;
                  break;
                case SessionStatus.ENDED:
                  updatedSession.endedAt = statusChange.changedAt;
                  break;
                case SessionStatus.CANCELLED:
                  dispatch(addCancelledSession(session.id));
                  dispatch(
                    sessionsApi.util.invalidateTags([
                      { type: RtkTagType.SESSION, id: session.id },
                      { type: RtkTagType.SESSIONS },
                    ]),
                  );
                  break;
                default:
                  throw new Error('Unknown session status.');
              }
            });
          },
        );
        await cacheEntryRemoved;
        sessionStatusSubscription.unsubscribe();
        usageSubscription.unsubscribe();
        eventSubscription.unsubscribe();
      },
    }),
    postSession: builder.mutation<ISessionDetail, INewSessionPayload>({
      query: (newSession) => ({
        url: '/sessions',
        method: 'POST',
        body: newSession,
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({
              message: TOAST_MESSAGES.errorSessionCreated,
              description: e.error.data,
            }),
          );
        }
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: RtkTagType.SESSIONS },
              { type: RtkTagType.CONNECTORS_FOR_NEW_SESSION },
              { type: RtkTagType.BOX },
            ]
          : [],
    }),
    patchEndSession: builder.mutation<ISession, string>({
      query: (sessionId) => ({
        url: `sessions/${sessionId}/endedAt`,
        method: 'PATCH',
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({
              message: TOAST_MESSAGES.errorSessionEnded,
              description: e.error.data,
            }),
          );
        }
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: RtkTagType.SESSIONS },
              { type: RtkTagType.SESSION, id: result.id },
              { type: RtkTagType.CONNECTORS_FOR_NEW_SESSION },
            ]
          : [],
    }),
  }),
});

export const {
  useGetSessionByIdQuery,
  useGetSessionsQuery,
  useLazyGetSessionsCsvQuery,
  usePatchEndSessionMutation,
  usePostSessionMutation,
} = sessionsApi;
