import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AppLayout } from '../../../AppLayout';
import { currentUserSelector, hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { theme } from '../../../theme';
import { UserPermission } from '../../../types/user.types';
import { SessionsTableWithFilter } from '../SessionsTable/SessionsTableWithFilter';
import { SessionPerspectiveSwitch } from './SessionPerspectiveSwitch';

const MESSAGES = defineMessages({
  heading: {
    id: 'sessions_overview.heading',
    defaultMessage: 'Sessions Overview',
  },
});

export const SessionsOverview = () => {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);

  const canCreateSession = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_SESSION),
  );
  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );
  const canManageCompanySessions = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_COMPANY_SESSIONS),
  );

  const currentUserSessionPermissions = [
    canCreateSession,
    canManageAssets,
    canManageCompanySessions,
  ];

  const filteredUserSessionPermissions = currentUserSessionPermissions.filter(
    (sessionPermission) => sessionPermission,
  );

  return (
    <AppLayout
      isAllowed={
        canManageAssets || canManageCompanySessions || canCreateSession
      }
    >
      <StyledBox p={theme.spacing.xLarge}>
        <StyledHeading variant="h1">
          {intl.formatMessage(MESSAGES.heading)}
        </StyledHeading>
        {
          // current user needs at least two permissions in order to toggle between session endpoints
          filteredUserSessionPermissions &&
            filteredUserSessionPermissions.length > 1 && (
              <SessionPerspectiveSwitch
                canCreateSession={canCreateSession}
                canManageAssets={canManageAssets}
                canManageCompanySessions={canManageCompanySessions}
              />
            )
        }
        <SessionsTableWithFilter currentUser={currentUser} />
      </StyledBox>
    </AppLayout>
  );
};
