import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../../../store/selectors/tags.selectors';
import { colors } from '../../../../../theme';
import {
  BoxTag,
  ConnectorTag,
  TagValueType,
} from '../../../../../types/tag.types';

interface IProps {
  tag: BoxTag | ConnectorTag;
  valueType: TagValueType;
  assetId: string;
}

export const TagsInfoTableValueColumn = ({
  assetId,
  tag,
  valueType,
}: IProps) => {
  const params = useParams();
  if (!params.assetId) return null;
  const boxId = params.assetId;

  const tagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(boxId, assetId, tag),
  );

  if (valueType === TagValueType.BOOL && tagStatus?.value) {
    return tagStatus.value === '0' ? (
      <CloseCircleOutlined style={{ color: colors.red.primary }} />
    ) : (
      <CheckCircleOutlined style={{ color: colors.green.primary }} />
    );
  }
  return <span>{tagStatus?.value}</span>;
};
