// store/slices/tags.slice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITagStatus, ITagStatusConnectorId } from '../../types/tag.types';

interface TagsState {
  statuses: {
    [connectorId: string]: ITagStatus[];
  };
  subscribedIds: string[];
}

const initialState: TagsState = {
  statuses: {},
  subscribedIds: [],
};

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    initConnector: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;

      if (!state.subscribedIds.includes(id)) {
        state.subscribedIds.push(id);
      }
    },
    resetTagStatuses: (state) => {
      return {
        ...state,
        statuses: {},
      };
    },
    setTagStatus: (state, action: PayloadAction<ITagStatusConnectorId>) => {
      const { connectorId, tag, value } = action.payload;
      
      // If the connectorId does not exist in the state, initialize it with an empty array
      if (!state.statuses[connectorId]) {
        state.statuses[connectorId] = [];
      }

      // Find the index of the tag in the connector's statuses
      const index = state.statuses[connectorId].findIndex(
        (status) => status.tag === tag
      );

      if (index < 0) {
        // If tag not found, add the new status
        state.statuses[connectorId].push({ tag, value, updatedAt: '' });
      } else {
        // If tag found, update the status at that index
        state.statuses[connectorId][index] = { tag, value, updatedAt: '' };
      }
    },
  },
});

export const { initConnector, resetTagStatuses, setTagStatus } = tagSlice.actions;

export default tagSlice.reducer;
