import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../store/selectors/tags.selectors';
import { StyledDiv } from '../../../styled/StyledDiv';
import { BoxType } from '../../../types/box.types';
import { BoxTag } from '../../../types/tag.types';
import { getBoxTypeForSymbolBoxStatus } from '../../../utils/boxes/getBoxTypeForSymbolBoxStatus';
import { valuePermillage } from '../../../utils/boxes/valuePermillage';
import { getBoxConnectivityStatus } from '../../../utils/boxes/getBoxConnectivityStatus';

interface IProps {
  boxId: string;
}

const Container = styled.div<{ boxIsOffline: boolean }>`
  text-align: right;
  opacity: ${(props) => (props.boxIsOffline ? '.3' : 1)};
`;

export const BoxTableConsumption = ({ boxId }: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);
  const symbolBoxTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_SYMBOL_BOX,
    ),
  );
  const boxTotWhStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V7_BOX_TOT_WH,
    ),
  );
  const boxTotalLitersTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V7_BOX_TOT_LITERS,
    ),
  );
  const boxTotPulsenStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V7_BOX_TOT_PULSEN,
    ),
  );

  const boxType = getBoxTypeForSymbolBoxStatus(symbolBoxTagStatus);

  return (
    <Container
      boxIsOffline={boxIsOffline}
      style={{ opacity: boxIsOffline ? '.3' : 1, textAlign: 'right' }}
    >
      {(boxType === BoxType.ELECTRICITY || boxType === BoxType.HYBRID) && (
        <>
          <StyledDiv>
            {valuePermillage(boxTotWhStatus?.value) || 0} kWh
          </StyledDiv>
        </>
      )}
      {(boxType === BoxType.WATER || boxType === BoxType.HYBRID) && (
        <StyledDiv>{boxTotalLitersTagStatus?.value || 0} m3</StyledDiv>
      )}
      {boxType === BoxType.WASTE && (
        <StyledDiv>{boxTotPulsenStatus?.value || 0} pulsen</StyledDiv>
      )}
    </Container>
  );
};
