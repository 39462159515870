import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { AppLayout } from '../../AppLayout';
import company from '../../assets/company.svg';
import employee from '../../assets/employee.svg';
import { SelectableCard } from '../../components/buttons/SelectableCard';
import { EmployeeRegisterMessage } from '../../components/login/EmployeeRegisterMessage';
import { RegisterForm } from '../../components/login/RegisterForm';
import { RegistrationActions } from '../../store/actions/registration.actions';
import { registrationActiveUserRoleSelector } from '../../store/selectors/registration.selectors';
import { StyledBox } from '../../styled/boxes/StyledBox';
import { FlexCentered } from '../../styled/flex/FlexCentered';
import { FlexCol } from '../../styled/flex/FlexCol';
import { FlexRow } from '../../styled/flex/FlexRow';
import { StyledHeading } from '../../styled/headings/StyledHeading';
import { colors, theme } from '../../theme';
import { UserRole } from '../../types/user.types';

const MESSAGES = defineMessages({
  title: {
    id: 'register_techmetrys_page.title',
    defaultMessage: 'I want to register as...',
  },
  [UserRole.NATURAL_PERSON]: {
    id: 'register_techmetrys_page.naturalPerson',
    defaultMessage: 'Natural Person',
  },
  [UserRole.COMPANY_EMPLOYEE]: {
    id: 'register_techmetrys_page.employee',
    defaultMessage: 'Employee',
  },
  [UserRole.COMPANY_OWNER]: {
    id: 'register_techmetrys_page.company',
    defaultMessage: 'Company',
  },
});

export const RegisterTechmetrysPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeRole = useSelector(registrationActiveUserRoleSelector);
  const isEmployee = activeRole === UserRole.COMPANY_EMPLOYEE;
  const isNaturalPerson = activeRole === UserRole.NATURAL_PERSON;
  const isCompany = activeRole === UserRole.COMPANY_OWNER;

  const handleSelectRoleClick = (role: UserRole) => {
    dispatch(RegistrationActions.setUserRole(role));
  };

  return (
    <AppLayout isAllowed>
      <FlexCentered width="100%" height="100%">
        <StyledBox
          width={550}
          p={theme.spacing.xxxLarge}
          bg={colors.white.primary}
        >
          <FlexCol>
            <StyledHeading variant="h4" mb={theme.spacing.large}>
              {intl.formatMessage(MESSAGES.title)}
            </StyledHeading>
            <FlexRow
              justifyContent="space-around"
              mb={theme.spacing.xxLarge}
              data-testid="roles-register"
            >
              <SelectableCard
                cardKey={UserRole.COMPANY_EMPLOYEE}
                onClick={() => handleSelectRoleClick(UserRole.COMPANY_EMPLOYEE)}
                iconSrc={employee}
                labelBelow={intl.formatMessage(
                  MESSAGES[UserRole.COMPANY_EMPLOYEE],
                )}
                active={isEmployee}
              />
              {/*Natural person is out of scope of MVP --> https://rombitbe.atlassian.net/browse/TEC2-254 */}
              {/*<SelectableCard*/}
              {/*  cardKey={UserRole.NATURAL_PERSON}*/}
              {/*  onClick={() => handleSelectRoleClick(UserRole.NATURAL_PERSON)}*/}
              {/*  iconSrc={naturalPerson}*/}
              {/*  labelBelow={intl.formatMessage(*/}
              {/*    MESSAGES[UserRole.NATURAL_PERSON],*/}
              {/*  )}*/}
              {/*  active={isNaturalPerson}*/}
              {/*/>*/}
              <SelectableCard
                cardKey={UserRole.COMPANY_OWNER}
                onClick={() => handleSelectRoleClick(UserRole.COMPANY_OWNER)}
                iconSrc={company}
                labelBelow={intl.formatMessage(
                  MESSAGES[UserRole.COMPANY_OWNER],
                )}
                active={isCompany}
              />
            </FlexRow>
            {isEmployee && <EmployeeRegisterMessage />}
            {isNaturalPerson && <RegisterForm role={UserRole.NATURAL_PERSON} />}
            {isCompany && <RegisterForm role={UserRole.COMPANY_OWNER} />}
          </FlexCol>
        </StyledBox>
      </FlexCentered>
    </AppLayout>
  );
};
