import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AppLayout } from '../../../AppLayout';
import { HeadingWithBackButton } from '../../../components/headings/HeadingWithBackButton';
import { EndSessionButtonWithModal } from '../../../components/sessions/EndSessionButtonWithModal';
import { useGetSessionByIdQuery } from '../../../store/api/sessions.api';
import {
  sessionDetailSelector,
  sessionHasBeenCancelled,
} from '../../../store/selectors/session.selectors';
import { hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { FlexRow } from '../../../styled/flex/FlexRow';
import { theme } from '../../../theme';
import { UserPermission } from '../../../types/user.types';
import { SessionDeletedModal } from './Modal/SessionDeletedModal';
import { SessionDetail } from './SessionDetail';

const MESSAGES = defineMessages({
  heading: {
    id: 'session_detail.heading',
    defaultMessage: 'Session detail',
  },
});

export const SessionDetailOverview = () => {
  const intl = useIntl();
  const { sessionId } = useParams();

  if (!sessionId) return null;

  useGetSessionByIdQuery(sessionId);

  const canCreateSession = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_SESSION),
  );
  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );
  const canManageCompanySessions = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_COMPANY_SESSIONS),
  );

  const sessionDetail = useSelector(sessionDetailSelector(sessionId));

  const isDeleted = useSelector(sessionHasBeenCancelled(sessionId));

  return (
    <AppLayout
      isAllowed={
        canManageAssets || canManageCompanySessions || canCreateSession
      }
    >
      {isDeleted && <SessionDeletedModal />}
      {sessionDetail && (
        <StyledBox p={theme.spacing.large}>
          <FlexRow justifyContent="space-between">
            <HeadingWithBackButton
              heading={intl.formatMessage(MESSAGES.heading)}
              variant="h4"
            />
          </FlexRow>
        </StyledBox>
      )}
      {sessionDetail && (
        <StyledBox px={theme.spacing.large}>
          <SessionDetail sessionDetail={sessionDetail} />
        </StyledBox>
      )}
    </AppLayout>
  );
};
