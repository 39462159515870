import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

import { appUrls } from '../config/url.constants';
import { useGetCurrentUserQuery } from '../store/api/users.api';
import { userTokenSelector } from '../store/selectors/user.selectors';

interface IProps {
  children: ReactNode;
}

export const PrivateRoute = ({ children }: IProps) => {
  const { isLoading: isFetchingUser } = useGetCurrentUserQuery();
  const isSignedIn = useSelector(userTokenSelector);

  if (!isSignedIn && !isFetchingUser) {
    return <Navigate to={appUrls.login.base} replace />;
  }

  return <>{children}</>;
};
