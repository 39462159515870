import React from 'react';
import { FlexRow } from '../../../../styled/flex/FlexRow';
import {
  StyledSubTextLight,
  NormalText,
} from '../../../../styled/text/Text';
import { theme } from '../../../../theme';
import { ESessionConsumptionUnit, ISessionDetail } from '../../../../types/session.types'


interface IProps {
  session: ISessionDetail;
}
export const SessionTechnicalInfo = ({ session }: IProps) => {
  const isElectricityBox = session.consumption.unit === ESessionConsumptionUnit.KWH
  const consumption = isElectricityBox ? 'kWh' : <span>m<sup>3</sup></span>

  return (
    <>
      {session && (
        <div>
          {isElectricityBox && <>
            <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
              <StyledSubTextLight>Max current L1 (A)</StyledSubTextLight>
              <NormalText width="22%" alignment="right">
                {session.metrics.maxCurrentL1 / 1000}
              </NormalText>
            </FlexRow>

            <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
              <StyledSubTextLight>Max current L2 (A)</StyledSubTextLight>
              <NormalText width="22%" alignment="right">
                {session.metrics.maxCurrentL2 / 1000}
              </NormalText>
            </FlexRow>

            <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
              <StyledSubTextLight>Max current L3 (A)</StyledSubTextLight>
              <NormalText width="22%" alignment="right">
                {session.metrics.maxCurrentL3 / 1000}
              </NormalText>
            </FlexRow>
          </>}

          <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
            <StyledSubTextLight>Meter start ({consumption})</StyledSubTextLight>
            <NormalText width="22%" alignment="right">
              {session.consumption.meterStart / 1000 || '-'}
            </NormalText>
          </FlexRow>

          <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
            <StyledSubTextLight>Meter stop ({consumption})</StyledSubTextLight>
            <NormalText width="22%" alignment="right">
              {session.consumption.meterReadout / 1000 || '-'}
            </NormalText>
          </FlexRow>
        </div>
      )}
    </>
  );
};
