import styled from '@emotion/styled';
import { Layout, Spin } from 'antd';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { FOB } from './components/buttons/FOB';
import { MobileNavBar } from './components/navigation/sideBar/MobileNavBar';
import { SideNavBar } from './components/navigation/sideBar/SideNavBar';
import { useGetCurrentUserQuery } from './store/api/users.api';
import { StyledBox } from './styled/boxes/StyledBox';
import { FlexCentered } from './styled/flex/FlexCentered';
import { StyledHeading } from './styled/headings/StyledHeading';
import { theme } from './theme';

interface AppLayoutProps {
  children?: React.ReactNode;
  isAllowed: boolean;
}

const { Content } = Layout;

const StyledLayout = styled(Layout)({
  height: '100vh',
  width: '100vw',
});

const StyledContent = styled(Content)({
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const MESSAGES = defineMessages({
  unauthorized: {
    id: 'unauthorized.title',
    defaultMessage: 'You do not have the right assigned permissions',
  },
});

export const AppLayout = ({ children, isAllowed }: AppLayoutProps) => {
  const intl = useIntl();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile] = useState(window.innerWidth < 768);
  const { isLoading: isFetchingUser } = useGetCurrentUserQuery();

  const handleSidebarClick = () => setIsCollapsed((prevState) => !prevState);

  const conditionalMainScreen = !isMobile ? (
    <StyledBox ml={isCollapsed ? '100px' : '220px'} width="100%">
      <StyledContent>{children}</StyledContent>
    </StyledBox>
  ) : (
    <StyledBox ml={0} width="100%">
      <StyledContent>{children}</StyledContent>
    </StyledBox>
  )

  const conditionalUnauthorized = !isMobile ? (
    <StyledBox
      ml={isCollapsed ? '100px' : '220px'}
      p={theme.spacing.xLarge}
    >
      <StyledHeading variant="h3">
        {intl.formatMessage(MESSAGES.unauthorized)}
      </StyledHeading>
    </StyledBox>
  ) : (
    <StyledBox
      ml={0}
      p={theme.spacing.xLarge}
    >
      <StyledHeading variant="h3">
        {intl.formatMessage(MESSAGES.unauthorized)}
      </StyledHeading>
    </StyledBox>
  )

  return (
    <StyledLayout>
      {isMobile ? (
        <MobileNavBar
          isCollapsed={isCollapsed}
          onSidebarClick={handleSidebarClick}
        />
      ) : (
        <SideNavBar
          isCollapsed={isCollapsed}
          onSidebarClick={handleSidebarClick}
        />
      )}
      {isFetchingUser ? (
        <FlexCentered height="100%" width="100%">
          <Spin />
        </FlexCentered>
      ) : (
        <>
          {isAllowed ? conditionalMainScreen : conditionalUnauthorized}
        </>
      )}

      <FOB />
    </StyledLayout>
  );
};

