import { SpaceProps } from 'styled-system';

import { IconSize } from './icon.types';
import type { IActiveSession } from './session.types';
import { ITagStatus } from './tag.types';

export interface IConnectorBox {
  activeSession: IActiveSession;
  id: string;
  index: number;
  tagStatuses: ITagStatus[];
}

export enum ResourceType {
  POWER = 'POWER',
  WATER = 'WATER',
}

export interface IConnectorCompatibilityStatus {
  connectorId: string;
  index: number;
  active: boolean;
  compatible: boolean;
  occupied: boolean;
}

export interface IConnectorCompatibilityStatusParams {
  boxId: string;
  vesselId: string;
  resourceType: ResourceType;
}

export interface IConnectorStatusIconProps extends SpaceProps {
  active?: boolean;
  connectorWebStatusValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any) => void;
  size?: IconSize;
}

export interface IConnectorStatusAlarmIconProps
  extends IConnectorStatusIconProps {
  assets?: boolean;
}

export interface IConnectorStatusWarningIconProps
  extends IConnectorStatusIconProps {
  assets?: boolean;
}

export enum ConnectorStatus {
  ALARM = 'ALARM',
  OK = 'OK',
  WARNING = 'WARNING',
  UNKNOWN = 'UNKNOWN',
}

export enum RailComponent {
  NONE,
  MOTOR,
  MOTOR_ACTIVE,
  MOTOR_ERROR,
  KWH_METER,
  KWH_METER_ERROR,
  CIRCUIT_BREAKER,
  CIRCUIT_BREAKER_ACTIVE,
  CIRCUIT_BREAKER_ERROR,
  RESIDUAL_CURRENT_DEVICE,
  RESIDUAL_CURRENT_DEVICE_ACTIVE,
  RESIDUAL_CURRENT_DEVICE_ERROR,
  RELAY,
  RELAY_ACTIVE,
  RELAY_ERROR,
}

export interface IRailConfiguration {
  components: RailComponent[];
  connected: boolean;
}

export enum ConnectorStatusValue {
  POINT_NOT_ACTIVE,
  WAITING_FOR_ACTIVATION,
  WAITING_FOR_ACTIVATION_CONNECTOR_IN,
  CHECKING_ACTIVATION_PARAMETERS,
  WEB_ACTIVATION_OK_WAITING_START,
  FORCED_ACTIVATION_OK_WAITING_START,
  WEB_ACTIVATION_OK,
  FORCED_ACTIVATION_OK,
  FLUSHING_CYCLE_ACTIVE,
  ACTIVE_CONSUMPTION,
  CONSUMPTION_HALTED,
  VESSEL_GROUND_TRIP,
  VESSEL_OVERCURRENT_TRIP,
  FINAL_VESSEL_FAULT,
  FAULT_CONNECTION_POINT,
  POINT_BLOCKED_MAX_CABINET_CURRENT,
  POINT_BLOCKED_OTHER_CABINET,
  POINT_BLOCKED_EXTERNAL_INPUT,
  POINT_BLOCKED,
}
