import { createSelector } from '@reduxjs/toolkit';

import { BoxTag, ConnectorTag } from '../../types/tag.types';
import { tagsApi } from '../api/tags.api';
// eslint-disable-next-line import/no-cycle
import { boxByIdSelector } from './box.selectors';
import { IPagedAssetLogFilters } from '../../types/filters.types';

export const tagsWebsocketSelector = (assetId: string) =>
  tagsApi.endpoints.tagsChannel.select(assetId);

export const websocketTagStatusByAssetIdAndTagSelector = (
  assetId: string,
  tag: BoxTag | ConnectorTag,
) =>
  createSelector(tagsWebsocketSelector(assetId), (tagsChannel) => {
    const statuses = tagsChannel?.data?.statuses;
    return statuses && statuses.find((status) => status.tag === tag);
  });

const websocketTagStatusesByAssetIdAndTagsSelector = (
  assetId: string,
  tags: (BoxTag | ConnectorTag)[],
) =>
  createSelector(tagsWebsocketSelector(assetId), (tagsChannel) => {
    const statuses = tagsChannel?.data?.statuses;
    return (
      statuses &&
      statuses.filter((status) =>
        tags.includes(status.tag as BoxTag | ConnectorTag),
      )
    );
  });

const tagStatusByRequestIdAndAssetIdTagSelector = (
  requestId: string,
  assetId: string,
  tag: BoxTag | ConnectorTag,
) =>
  createSelector(boxByIdSelector(requestId), (box) => {
    const boxTagStatuses = box?.tagStatuses;
    if (requestId === assetId) {
      return boxTagStatuses?.find((status) => status.tag === tag);
    }
    const connector = box?.connectors.find((conn) => conn.id === assetId);
    return connector?.tagStatuses?.find((status) => status.tag === tag);
  });

const tagStatusesByRequestIdAndAssetIdTagsSelector = (
  requestId: string,
  assetId: string,
  tags: (BoxTag | ConnectorTag)[],
) =>
  createSelector(boxByIdSelector(requestId), (box) => {
    const boxTagStatuses = box?.tagStatuses;
    if (requestId === assetId) {
      return boxTagStatuses?.filter((status) => tags.includes(status.tag));
    }
    const connector = box?.connectors.find((conn) => conn.id === assetId);
    return connector?.tagStatuses?.filter((status) =>
      tags.includes(status.tag),
    );
  });

export const mostRecentTagStatusByRequestIdAssetIdAndTagSelector = (
  requestId: string,
  assetId: string,
  tag: BoxTag | ConnectorTag,
) =>
  createSelector(
    websocketTagStatusByAssetIdAndTagSelector(assetId, tag),
    tagStatusByRequestIdAndAssetIdTagSelector(requestId, assetId, tag),
    (status, initialTagStatus) => {
      if ((status?.updatedAt || 0) > (initialTagStatus?.updatedAt || 0)) {
        return status;
      }
      return initialTagStatus;
    },
  );

export const mostRecentTagStatusesByRequestIdAssetIdAndTagsSelector = (
  requestId: string,
  assetId: string,
  tags: (BoxTag | ConnectorTag)[],
) =>
  createSelector(
    websocketTagStatusesByAssetIdAndTagsSelector(assetId, tags),
    tagStatusesByRequestIdAndAssetIdTagsSelector(requestId, assetId, tags),
    (websocketTagStatuses, initialTagStatuses) => {
      if (!websocketTagStatuses || websocketTagStatuses.length === 0) {
        return initialTagStatuses;
      }

      return initialTagStatuses?.map((initialStatus) => {
        const websocketTagStatus = websocketTagStatuses?.find(
          (websocketStatus) => websocketStatus.tag === initialStatus.tag,
        );

        if (!websocketTagStatus) {
          return initialStatus;
        }
        return (initialStatus.updatedAt || 0) >
          (websocketTagStatus?.updatedAt || 0)
          ? initialStatus
          : websocketTagStatus;
      });
    },
  );

export const assetLogRtkState = (tagValueFilters: IPagedAssetLogFilters) => {
  return tagsApi.endpoints.postAssetLogFiltered.select(tagValueFilters);
}

export const assetLogSelector = (tagValueFilters: IPagedAssetLogFilters) =>
  createSelector(
    assetLogRtkState(tagValueFilters),
    (state) => {
      return state.data;
    },
  );

