import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { RowSelectionType } from 'antd/lib/table/interface';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { appUrls } from '../../../config/url.constants';
import {
  VESSEL_FREQUENCY_MESSAGES,
  VESSEL_POWER_TYPE_MESSAGES,
  VESSEL_TYPE_MESSAGES,
} from '../../../consts/vessel.consts';
import { vesselsPageResponseSelector } from '../../../store/selectors/vessel.selectors';
import { updateRequestedTablePage } from '../../../store/slices/tableMetaInfo.slice';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { StyledTable } from '../../../styled/table/StyledTable';
import { IVesselFilters } from '../../../types/filters.types';
import { Table } from '../../../types/table.types';
import {
  IVessel,
  PowerPhaseAndAmp,
  VesselFrequency,
  VesselType,
} from '../../../types/vessel.types';

enum VesselDataType {
  NAME = 'name',
  ENI_NUMBER = 'eniNumber',
  TYPE = 'type',
  POWER_FREQUENCY = 'powerFrequency',
  POWER_TYPE = 'powerPhaseAndAmp',
}

const MESSAGES = defineMessages({
  editVessel: {
    id: 'vessels_table.editVessel',
    defaultMessage: 'Edit vessel',
  },
  [VesselDataType.NAME]: {
    id: 'vessels_table.name',
    defaultMessage: 'Vessel Name',
  },
  [VesselDataType.ENI_NUMBER]: {
    id: 'vessels_table.eniNumber',
    defaultMessage: 'E.N.I Number',
  },
  [VesselDataType.TYPE]: {
    id: 'vessels_table.type',
    defaultMessage: 'Vessel Type',
  },
  [VesselDataType.POWER_FREQUENCY]: {
    id: 'vessels_table.powerFrequency',
    defaultMessage: 'Frequency',
  },
  [VesselDataType.POWER_TYPE]: {
    id: 'vessels_table.powerType',
    defaultMessage: 'Power Type',
  },
});

interface IProps {
  canManageVessels: boolean;
  onSelectVessel: (vesselIds: string[]) => void;
  requestedVesselFilters: IVesselFilters;
}

export const VesselsTable = ({
  canManageVessels,
  onSelectVessel,
  requestedVesselFilters,
}: IProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns: ColumnsType<IVessel> = [
    {
      title: intl.formatMessage(MESSAGES[VesselDataType.NAME]),
      dataIndex: VesselDataType.NAME,
    },
    {
      title: intl.formatMessage(MESSAGES[VesselDataType.ENI_NUMBER]),
      dataIndex: VesselDataType.ENI_NUMBER,
    },
    {
      title: intl.formatMessage(MESSAGES[VesselDataType.TYPE]),
      dataIndex: VesselDataType.TYPE,
      render: (type: VesselType) =>
        intl.formatMessage(VESSEL_TYPE_MESSAGES[type]),
    },
    {
      title: intl.formatMessage(MESSAGES[VesselDataType.POWER_FREQUENCY]),
      dataIndex: VesselDataType.POWER_FREQUENCY,
      render: (frequency: VesselFrequency) =>
        intl.formatMessage(VESSEL_FREQUENCY_MESSAGES[frequency]),
    },
    {
      title: intl.formatMessage(MESSAGES[VesselDataType.POWER_TYPE]),
      dataIndex: VesselDataType.POWER_TYPE,
      render: (type: PowerPhaseAndAmp) =>
        intl.formatMessage(VESSEL_POWER_TYPE_MESSAGES[type]),
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      render: (record: IVessel) => {
        if (canManageVessels) {
          return (
            <StyledButton
              variant="primary"
              ml="auto"
              onClick={() => navigate(appUrls.vessels.edit(record.id))}
              data-testid="btn-edit-vessel"
            >
              <EditOutlined />
              {intl.formatMessage(MESSAGES.editVessel)}
            </StyledButton>
          );
        }
        return null;
      },
    },
  ];

  const rowSelection = {
    type: 'checkbox' as RowSelectionType,
    onChange: (selectedRowKeys: React.Key[], selectedRows: IVessel[]) => {
      onSelectVessel(selectedRows.map((vessel) => vessel.id));
    },
  };

  const handleChangePage = (requestedPage: number) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.VESSELS_TABLE,
        value: requestedPage - 1,
      }),
    );
  };

  const pagedVessels = useSelector(
    vesselsPageResponseSelector(requestedVesselFilters),
  );

  return (
    <>
      {pagedVessels && (
        <StyledTable
          columns={columns}
          onRow={(record) => {
            return {
              onClick: (ev) => {
                if ((ev.target as HTMLInputElement).type === 'checkbox') {
                  return null;
                }
                return navigate(appUrls.vessels.edit(record.id));
              },
            };
          }}
          rowSelection={{ ...rowSelection }}
          dataSource={pagedVessels.content}
          rowKey={(record: IVessel) => record.id}
          pagination={{
            current: pagedVessels.number + 1,
            onChange: (requestedPage: number) =>
              handleChangePage(requestedPage),
            pageSize: pagedVessels.size,
            position: ['bottomLeft'],
            total: pagedVessels.totalElements,
            showSizeChanger: false,
          }}
        />
      )}
    </>
  );
};
