import { Tooltip } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ForceConnectorActionIcon } from '../../../../../../components/connectors/icons/connectorsTable/actions/ForceConnectorActionIcon';
import { RefreshConnectorActionIcon } from '../../../../../../components/connectors/icons/connectorsTable/actions/RefreshConnectorActionIcon';
import { ResetConnectorActionIcon } from '../../../../../../components/connectors/icons/connectorsTable/actions/ResetConnectorActionIcon';
import { StartConnectorActionIcon } from '../../../../../../components/connectors/icons/connectorsTable/actions/StartConnectorActionIcon';
import { toasts } from '../../../../../../components/feedback/toasts';
import { usePostAssetCommandMutation } from '../../../../../../store/api/assets.api';
import { useTagsChannelQuery } from '../../../../../../store/api/tags.api';
import { FlexBox } from '../../../../../../styled/flex/FlexBox';
import { theme } from '../../../../../../theme';
import { IconSize } from '../../../../../../types/icon.types';
import {
  ConnectorTag,
  TagActionValue,
} from '../../../../../../types/tag.types';
import { AssetAction } from '../../AssetAction';

const MESSAGES = defineMessages({
  actionSent: {
    id: 'send_action_form.actionSent',
    defaultMessage: 'Action has been sent.',
  },
  startConnector: {
    id: 'send_action_form.startConnector',
    defaultMessage: 'Start connector',
  },
  resetConnector: {
    id: 'send_action_form.resetConnector',
    defaultMessage: 'Reset connector',
  },
  refreshConnector: {
    id: 'send_action_form.refreshConnector',
    defaultMessage: 'Refresh connector',
  },
  forceAuthorisation: {
    id: 'send_action_form.forceAuthorisation',
    defaultMessage: 'Force authorisation',
  },
});

interface IProps {
  connectorId: string;
  boxId: string;
}

export const ConnectorActionsList = ({ boxId, connectorId }: IProps) => {
  const intl = useIntl();

  useTagsChannelQuery(connectorId);

  const [postCommand] = usePostAssetCommandMutation();

  const handleActionClick = (tagName: ConnectorTag) => {
    postCommand({
      assetId: connectorId,
      command: {
        tag: tagName,
        input: TagActionValue.TRUE,
      },
    });
    toasts.success({
      autoHide: true,
      message: intl.formatMessage(MESSAGES.actionSent),
    });
  };

  return (
    <FlexBox alignItems="center">
      <Tooltip title={intl.formatMessage(MESSAGES.startConnector)}>
        <StartConnectorActionIcon
          onClick={() =>
            handleActionClick(ConnectorTag.V8_SIMULATE_STARTBUTTON)
          }
          size={IconSize.XLARGE}
        />
      </Tooltip>
      <Tooltip title={intl.formatMessage(MESSAGES.resetConnector)}>
        <ResetConnectorActionIcon
          mx={theme.spacing.medium}
          onClick={() => handleActionClick(ConnectorTag.V8_RESET_POINT)}
          size={IconSize.XLARGE}
        />
      </Tooltip>
      <Tooltip title={intl.formatMessage(MESSAGES.forceAuthorisation)}>
        {/*tooltip wont render without empty div 🤷‍♂️*/}
        <div>
          <AssetAction
            requestId={boxId}
            assetId={connectorId}
            tag={ConnectorTag.V8_AUTORISATION_FORCED}
            childrenIcon={
              <ForceConnectorActionIcon
                mt={theme.spacing.xxxSmall}
                size={IconSize.SMALL}
              />
            }
          />
        </div>
      </Tooltip>
      <Tooltip title={intl.formatMessage(MESSAGES.refreshConnector)}>
        <RefreshConnectorActionIcon
          ml={theme.spacing.medium}
          onClick={() => handleActionClick(ConnectorTag.V8_REFRESH_POINT)}
          size={IconSize.XLARGE}
        />
      </Tooltip>
    </FlexBox>
  );
};
