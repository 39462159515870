import { IPagedResponse } from './api.types';

export enum VesselFrequency {
  HZ_50 = 'HZ_50',
  HZ_60 = 'HZ_60',
}

export enum PowerPhaseAndAmp {
  PW_230V_16A = 'PW_230V_16A',
  PW_230V_32A = 'PW_230V_32A',
  PW_3X400V_63A = 'PW_3X400V_63A',
  PW_3X400V_125A = 'PW_3X400V_125A',
  PW_3X400V_400A = 'PW_3X400V_400A',
  PW_3X400V_800A = 'PW_3X400V_800A',
  OTHER = 'OTHER',
}

export enum VesselType {
  INLAND = 'INLAND',
  BARGE_INLAND = 'BARGE_INLAND',
  TANKER_INLAND = 'TANKER_INLAND',
  BULK_INLAND = 'BULK_INLAND',
  CARGO_TUG_BOAT = 'CARGO_TUG_BOAT',
  FISHING_BOAT = 'FISHING_BOAT',
  RIVER_CRUISE = 'RIVER_CRUISE',
  OTHER = 'OTHER',
}

export interface IVessel {
  eniNumber: string;
  id: string;
  powerFrequency: VesselFrequency;
  powerPhaseAndAmp: PowerPhaseAndAmp;
  name: string;
  type: VesselType;
}

export type IPostVesselsDTO = Omit<IVessel, 'id'>;

export type IUpdateVesselsDTO = {
  id: string;
  payload: IPostVesselsDTO;
};

export type IVesselsResponse = IPagedResponse<IVessel>;

export enum VesselFilter {
  ENI_NUMBER = 'ENI_NUMBER',
  NAME = 'NAME',
  TYPE = 'TYPE',
}

export enum VesselFormValues {
  NAME = 'name',
  ENI_NUMBER = 'eniNumber',
  TYPE = 'type',
  FREQUENCY = 'frequency',
  POWER_TYPE = 'powerType',
}
