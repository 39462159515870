import { IPagedResponse } from './api.types';

export enum TagDirection {
  IN = 'IN',
  OUT = 'OUT',
}
export enum TagValueType {
  REAL = 'REAL',
  BOOL = 'BOOL',
}
export enum TagSignalType {
  ANALOG = 'ANALOG',
  DIGITAL = 'DIGITAL',
}

export enum TagActionValue {
  FALSE = '0',
  TRUE = '1',
}

export enum BoxTag {
  V2_COOLING_ON = 'V2_COOLING_ON',
  V2_ETHERNET_PRESENT = 'V2_ETHERNET_PRESENT',
  V2_HEATING_ON = 'V2_HEATING_ON',
  V2_HYGROSSTAAT_PRESENT = 'V2_HYGROSSTAAT_PRESENT',
  V2_SERVICE_MODE_ACTIVE = 'V2_SERVICE_MODE_ACTIVE',
  V2_SYMBOL_ALARM = 'V2_SYMBOL_ALARM',
  V2_SYMBOL_BOX = 'V2_SYMBOL_BOX',
  V2_TECH_BOX_STATUS = 'V2_TECH_BOX_STATUS',
  V3_BOX_TEMPERATURE = 'V3_BOX_TEMPERATURE',
  V3_BOX_WARNING = 'V3_BOX_WARNING',
  V3_CPU_TEMPERATURE = 'V3_CPU_TEMPERATURE',
  V3_EXPANTION_MODULE = 'V3_EXPANTION_MODULE',
  V3_FUSE_AUXILLIARIES = 'V3_FUSE_AUXILLIARIES',
  V3_FUSE_EXT24VDC = 'V3_FUSE_EXT24VDC',
  V3_HYGROSSTAAT = 'V3_HYGROSSTAAT',
  V3_RTU_24_VDC = 'V3_RTU_24_VDC',
  V3_SERVICE_DOOR = 'V3_SERVICE_DOOR',
  V3_UPS = 'V3_UPS',
  V4_BOX_ALARM = 'V4_BOX_ALARM',
  V4_CHANNEL_STATE = 'V4_CHANNEL_STATE',
  V4_CPU = 'V4_CPU',
  V4_EXPANTION_MODULE = 'V4_EXPANTION_MODULE',
  V4_FUSE_AUTOMATION = 'V4_FUSE_AUTOMATION',
  V4_INCOMING_FASES = 'V4_INCOMING_FASES',
  V4_LICHTNING_PROTECTION = 'V4_LICHTNING_PROTECTION',
  V4_MAINSWITCH = 'V4_MAINSWITCH',
  V4_VOLTAGE = 'V4_VOLTAGE',
  V7_BOX_ACT_W = 'V7_BOX_ACT_W',
  V7_BOX_MAX_W = 'V7_BOX_MAX_W',
  V7_BOX_POINTS = 'V7_BOX_POINTS',
  V7_BOX_POINTS_ACTIVE = 'V7_BOX_POINTS_ACTIVE',
  V7_BOX_POINTS_FAULT = 'V7_BOX_POINTS_FAULT',
  V7_BOX_TOT_LITERS = 'V7_BOX_TOT_LITERS',
  V7_BOX_TOT_PULSEN = 'V7_BOX_TOT_PULSEN',
  V7_BOX_TOT_WH = 'V7_BOX_TOT_WH',
  V7_CPU_FIRMWARE = 'V7_CPU_FIRMWARE',
  V7_CPU_TEMPERATURE = 'V7_CPU_TEMPERATURE',
  V7_CPU_TYPE = 'V7_CPU_TYPE',
  V7_CPU_VOLTAGE = 'V7_CPU_VOLTAGE',
  V7_HUMIDITY = 'V7_HUMIDITY',
  V7_RESERVED_CURRENT = 'V7_RESERVED_CURRENT',
  V7_SOFTWARE_VERSION = 'V7_SOFTWARE_VERSION',
  V7_TEMPERATURE = 'V7_TEMPERATURE',
  V8_FORCE_HEATING = 'V8_FORCE_HEATING',
  V8_MAIN_RESET_BOX = 'V8_MAIN_RESET_BOX',
  V8_REFRESH_BOX = 'V8_REFRESH_BOX',
  V8_SET_AMBIENT_LIGHT = 'V8_SET_AMBIENT_LIGHT',
  V8_SET_BOX_OUT_OF_SERVICE = 'V8_SET_BOX_OUT_OF_SERVICE',
  V8_SET_FORCED_CONTROL_OFF = 'V8_SET_FORCED_CONTROL_OFF',
  V8_SET_SERVICE_MODE = 'V8_SET_SERVICE_MODE',
  V8_TEST_MODE = 'V8_TEST_MODE',
  V9_AUTO_RESET_TIME = 'V9_AUTO_RESET_TIME',
  V9_BOX_ALARMS_ACTIVE = 'V9_BOX_ALARMS_ACTIVE',
  V9_BOX_ID = 'V9_BOX_ID',
  V9_BOX_MAX_POWER = 'V9_BOX_MAX_POWER',
  V9_CHANGE_DOOR_IO = 'V9_CHANGE_DOOR_IO',
  V9_COMM_TYPE_ETHERNET = 'V9_COMM_TYPE_ETHERNET',
  V9_CONFIG_LICENSE = 'V9_CONFIG_LICENSE',
}

export enum BoxWarningTag {
  V3_BOX_TEMPERATURE = BoxTag.V3_BOX_TEMPERATURE,
  V3_BOX_WARNING = BoxTag.V3_BOX_WARNING,
  V3_CPU_TEMPERATURE = BoxTag.V3_CPU_TEMPERATURE,
  V3_EXPANTION_MODULE = BoxTag.V3_EXPANTION_MODULE,
  V3_FUSE_AUXILLIARIES = BoxTag.V3_FUSE_AUXILLIARIES,
  V3_FUSE_EXT24VDC = BoxTag.V3_FUSE_EXT24VDC,
  V3_HYGROSSTAAT = BoxTag.V3_HYGROSSTAAT,
  V3_RTU_24_VDC = BoxTag.V3_RTU_24_VDC,
  V3_SERVICE_DOOR = BoxTag.V3_SERVICE_DOOR,
  V3_UPS = BoxTag.V3_UPS,
}

export enum BoxAlarmTag {
  V4_BOX_ALARM = BoxTag.V4_BOX_ALARM,
  V4_CHANNEL_STATE = BoxTag.V4_CHANNEL_STATE,
  V4_CPU = BoxTag.V4_CPU,
  V4_EXPANTION_MODULE = BoxTag.V4_EXPANTION_MODULE,
  V4_FUSE_AUTOMATION = BoxTag.V4_FUSE_AUTOMATION,
  V4_INCOMING_FASES = BoxTag.V4_INCOMING_FASES,
  V4_LICHTNING_PROTECTION = BoxTag.V4_LICHTNING_PROTECTION,
  V4_MAINSWITCH = BoxTag.V4_MAINSWITCH,
  V4_VOLTAGE = BoxTag.V4_VOLTAGE,
}

export enum ConnectorTag {
  V1_CONS_ACTUAL = 'V1_CONS_ACTUAL',
  V1_CONS_SESSION = 'V1_CONS_SESSION',
  V1_METER_READOUT = 'V1_METER_READOUT',
  V1_METER_START = 'V1_METER_START',
  V1_METER_STOP = 'V1_METER_STOP',
  V1_STOP_BIT = 'V1_STOP_BIT',
  V1_WEB_STATUS = 'V1_WEB_STATUS',
  V1_WEB_STATUS_OLD = 'V1_WEB_STATUS_OLD',
  V2_AUTORISATION_CONTROL = 'V2_AUTORISATION_CONTROL',
  V2_EXECUTE_QUERY = 'V2_EXECUTE_QUERY',
  V2_MAGNET_ON = 'V2_MAGNET_ON',
  V2_MIMIC_01 = 'V2_MIMIC_01',
  V2_MIMIC_02 = 'V2_MIMIC_02',
  V2_MIMIC_03 = 'V2_MIMIC_03',
  V2_MIMIC_04 = 'V2_MIMIC_04',
  V2_MIMIC_05 = 'V2_MIMIC_05',
  V2_MIMIC_06 = 'V2_MIMIC_06',
  V2_POINT_ACTIVE = 'V2_POINT_ACTIVE',
  V2_POINT_BLOCKED = 'V2_POINT_BLOCKED',
  V2_PRG_STEP = 'V2_PRG_STEP',
  V2_SESSION_ACTIVE = 'V2_SESSION_ACTIVE',
  V2_SHOW_REFRESH_BUTTON = 'V2_SHOW_REFRESH_BUTTON',
  V2_SHOW_RESET_BUTTON = 'V2_SHOW_RESET_BUTTON',
  V2_STARTED = 'V2_STARTED',
  V2_STARTED_BY = 'V2_STARTED_BY',
  V2_SYMBOL_AUTORISATION = 'V2_SYMBOL_AUTORISATION',
  V2_SYMBOL_CONTROL = 'V2_SYMBOL_CONTROL',
  V2_SYMBOL_PILOT = 'V2_SYMBOL_PILOT',
  V2_SYMBOL_START = 'V2_SYMBOL_START',
  V2_TECH_ACTION = 'V2_TECH_ACTION',
  V2_TECH_STATUS = 'V2_TECH_STATUS',
  V2_UNIT = 'V2_UNIT',
  V3_MAINTENANCE = 'V3_MAINTENANCE',
  V3_POINT_WARNING = 'V3_POINT_WARNING',
  V3_SIMULATION_MODE = 'V3_SIMULATION_MODE',
  V3_TAMPERING = 'V3_TAMPERING',
  V3_TEST_MODE = 'V3_TEST_MODE',
  V3_VESSEL_DEFINITIEVE = 'V3_VESSEL_DEFINITIEVE',
  V3_VESSEL_DIFF_TRIP = 'V3_VESSEL_DIFF_TRIP',
  V3_VESSEL_OVERLOAD_TRIP = 'V3_VESSEL_OVERLOAD_TRIP',
  V4_BENDER = 'V4_BENDER',
  V4_BOX_FAIL = 'V4_BOX_FAIL',
  V4_CABLE_PULL = 'V4_CABLE_PULL',
  V4_DIFF_RESET = 'V4_DIFF_RESET',
  V4_METER = 'V4_METER',
  V4_METER_FUSE = 'V4_METER_FUSE',
  V4_POINT_ALARM = 'V4_POINT_ALARM',
  V4_PRIME_DRIVE = 'V4_PRIME_DRIVE',
  V4_SAFETY_LOOP = 'V4_SAFETY_LOOP',
  V4_SECOND_DRIVE = 'V4_SECOND_DRIVE',
  V4_VOLTAGE_ON_CABLE = 'V4_VOLTAGE_ON_CABLE',
  V4_WEB_REMOVAL_FAIL = 'V4_WEB_REMOVAL_FAIL',
  V6_AUTHORISATION = 'V6_AUTHORISATION',
  V6_PILOOTCONTACT = 'V6_PILOOTCONTACT',
  V6_POINT_BLOCKED = 'V6_POINT_BLOCKED',
  V6_START_CONDITION = 'V6_START_CONDITION',
  V6_USER_DOOR = 'V6_USER_DOOR',
  V6_VESSEL_BENDER = 'V6_VESSEL_BENDER',
  V6_VESSEL_SAFETY_LOOP = 'V6_VESSEL_SAFETY_LOOP',
  V6_VOLTAGE_ON_CABLE = 'V6_VOLTAGE_ON_CABLE',
  V7_ACTIVE_W = 'V7_ACTIVE_W',
  V7_BENDER_DETECTED = 'V7_BENDER_DETECTED',
  V7_CURRENT_L1 = 'V7_CURRENT_L1',
  V7_CURRENT_L2 = 'V7_CURRENT_L2',
  V7_CURRENT_L3 = 'V7_CURRENT_L3',
  V7_FB_STARTBUTTON = 'V7_FB_STARTBUTTON',
  V7_FB_STOPBUTTON = 'V7_FB_STOPBUTTON',
  V7_MAX_POWER = 'V7_MAX_POWER',
  V7_PILOT_CONTACT_IN = 'V7_PILOT_CONTACT_IN',
  V7_POINT_CONSUMING = 'V7_POINT_CONSUMING',
  V7_POWER_FACTOR = 'V7_POWER_FACTOR',
  V7_PRIME_DRIVE = 'V7_PRIME_DRIVE',
  V7_PRIME_DRIVE_ACTIVE = 'V7_PRIME_DRIVE_ACTIVE',
  V7_PRIME_IN = 'V7_PRIME_IN',
  V7_RESET_TIME = 'V7_RESET_TIME',
  V7_SECONDE_DRIVE_ACTIVE = 'V7_SECONDE_DRIVE_ACTIVE',
  V7_SECOND_DRIVE = 'V7_SECOND_DRIVE',
  V7_SECOND_IN = 'V7_SECOND_IN',
  V7_SWITCHING_NSX = 'V7_SWITCHING_NSX',
  V7_SWITCHING_RCA = 'V7_SWITCHING_RCA',
  V7_SWITCHING_RELAY = 'V7_SWITCHING_RELAY',
  V7_TAMPERING_VALUE = 'V7_TAMPERING_VALUE',
  V7_TRIPPED = 'V7_TRIPPED',
  V7_TRIPS = 'V7_TRIPS',
  V7_VOLTAGE_L1_L2 = 'V7_VOLTAGE_L1_L2',
  V7_VOLTAGE_L2_L3 = 'V7_VOLTAGE_L2_L3',
  V7_VOLTAGE_L3_L1 = 'V7_VOLTAGE_L3_L1',
  V8_AUTORISATION_FORCED = 'V8_AUTORISATION_FORCED',
  V8_AUTORISATION_WEB = 'V8_AUTORISATION_WEB',
  V8_DEACTIVATE_MAGNEET_CC1 = 'V8_DEACTIVATE_MAGNEET_CC1',
  V8_OVERRIDE_SAFTEY = 'V8_OVERRIDE_SAFTEY',
  V8_REFRESH_POINT = 'V8_REFRESH_POINT',
  V8_RESET_POINT = 'V8_RESET_POINT',
  V8_SET_MAINTENANCE = 'V8_SET_MAINTENANCE',
  V8_MAIN_RESET_BOX = 'V8_MAIN_RESET_BOX',
  V8_SIMULATE_PILOTCONTACT = 'V8_SIMULATE_PILOTCONTACT',
  V8_SIMULATE_STARTBUTTON = 'V8_SIMULATE_STARTBUTTON',
  V8_SIMULATE_STOPBUTTON = 'V8_SIMULATE_STOPBUTTON',
  V8_STOP_WEB_AUTORISATION = 'V8_STOP_WEB_AUTORISATION',
  V8_TRIP_COMMAND = 'V8_TRIP_COMMAND',
  V9_ACTIVE_PUSHBUTTONS = 'V9_ACTIVE_PUSHBUTTONS',
  V9_CONFIG_DRIVE = 'V9_CONFIG_DRIVE',
  V9_CONFIG_METER = 'V9_CONFIG_METER',
  V9_DELAY_FAULT_CHECK = 'V9_DELAY_FAULT_CHECK',
  V9_DELAY_READ_OUT = 'V9_DELAY_READ_OUT',
  V9_DELAY_SECOND_PRIM = 'V9_DELAY_SECOND_PRIM',
  V9_FEEDBACK_PRIME_OFF = 'V9_FEEDBACK_PRIME_OFF',
  V9_FEEDBACK_SECOND_OFF = 'V9_FEEDBACK_SECOND_OFF',
  V9_LANGUAGE = 'V9_LANGUAGE',
  V9_MAX_CURRENT_POINT = 'V9_MAX_CURRENT_POINT',
  V9_PULS_DIVIDER = 'V9_PULS_DIVIDER',
  V9_SET_AUT_METER = 'V9_SET_AUT_METER',
  V9_SET_BENDER_ACTIVE = 'V9_SET_BENDER_ACTIVE',
}

export enum ConnectorAlarmTag {
  V4_BENDER = ConnectorTag.V4_BENDER,
  V4_BOX_FAIL = ConnectorTag.V4_BOX_FAIL,
  V4_CABLE_PULL = ConnectorTag.V4_CABLE_PULL,
  V4_DIFF_RESET = ConnectorTag.V4_DIFF_RESET,
  V4_METER = ConnectorTag.V4_METER,
  V4_METER_FUSE = ConnectorTag.V4_METER_FUSE,
  V4_POINT_ALARM = ConnectorTag.V4_POINT_ALARM,
  V4_PRIME_DRIVE = ConnectorTag.V4_PRIME_DRIVE,
  V4_SAFETY_LOOP = ConnectorTag.V4_SAFETY_LOOP,
  V4_SECOND_DRIVE = ConnectorTag.V4_SECOND_DRIVE,
  V4_VOLTAGE_ON_CABLE = ConnectorTag.V4_VOLTAGE_ON_CABLE,
  V4_WEB_REMOVAL_FAIL = ConnectorTag.V4_WEB_REMOVAL_FAIL,
}

export enum ConnectorWarningTag {
  V3_MAINTENANCE = ConnectorTag.V3_MAINTENANCE,
  V3_POINT_WARNING = ConnectorTag.V3_POINT_WARNING,
  V3_SIMULATION_MODE = ConnectorTag.V3_SIMULATION_MODE,
  V3_TAMPERING = ConnectorTag.V3_TAMPERING,
  V3_TEST_MODE = ConnectorTag.V3_TEST_MODE,
  V3_VESSEL_DEFINITIEVE = ConnectorTag.V3_VESSEL_DEFINITIEVE,
  V3_VESSEL_DIFF_TRIP = ConnectorTag.V3_VESSEL_DIFF_TRIP,
  V3_VESSEL_OVERLOAD_TRIP = ConnectorTag.V3_VESSEL_OVERLOAD_TRIP,
}

export interface ITagStatus {
  tag: BoxTag | ConnectorTag;
  value: string;
  updatedAt: string;
}

export interface ITagStatusConnectorId extends ITagStatus {
  connectorId: string;
}

export interface ITagInfo extends ITagStatus {
  category: string;
  tagId: number;
  description: string;
  valueType: TagValueType;
  signalType: TagSignalType;
  direction: TagDirection;
}

interface IAssetLogTag {
  id: string;
  description: string;
  type: TagValueType;
}

export interface IAssetLogs {
  assetId: string;
  tag: IAssetLogTag;
  value: string;
  timestamp: Date;
}

export type IAssetLogResponse = IPagedResponse<IAssetLogs>;

