import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { appUrls } from '../config/url.constants';
import { BoxDetail } from '../pages/Boxes/BoxDetail/BoxDetail';
import { BoxesOverview } from '../pages/Boxes/BoxesOverview/BoxesOverview';
import { ConfirmEmailPage } from '../pages/Login/ConfirmEmailPage';
import { LandingsPage } from '../pages/Login/LandingsPage';
import { LoginPage } from '../pages/Login/LoginPage';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { MaintenanceOverview } from '../pages/Maintenance/MaintenanceOverview';
import { TaskEdit } from '../pages/Maintenance/Tasks/TaskEdit';
import { RegisterCognitoPage } from '../pages/Login/RegisterCognitoPage';
import { ResetPasswordRequestPage } from '../pages/Login/ResetPasswordRequestPage';
import { ResetPasswordSetPage } from '../pages/Login/ResetPasswordSetPage';
import { ResetPasswordCode } from '../pages/Login/ResetPasswordCode';
import { SessionDetailOverview } from '../pages/Sessions/SessionDetail/SessionDetailOverview';
import { SessionsOverview } from '../pages/Sessions/SessionsOverview/SessionsOverview';
import { ProfilePage } from '../pages/Settings/ProfilePage';
import { UserForm } from '../pages/Users/UserForm/UserForm';
import { UsersOverview } from '../pages/Users/UsersOverview/UsersOverview';
import { VesselForm } from '../pages/Vessels/VesselForm/VesselForm';
import { VesselsOverview } from '../pages/Vessels/VesselsOverview/VesselsOverview';
import { userTokenSelector } from '../store/selectors/user.selectors';
import { BoxDetailTabs, TaskTabs } from '../types/tab.types';
import { PrivateRoute } from './PrivateRoute';

export const AppRoutes = () => {
  const isSignedIn = useSelector(userTokenSelector);

  return (
    <Router>
      <Routes>
        <Route
          path={appUrls.dashboard.base}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.assets.base}
          element={
            <PrivateRoute>
              <BoxesOverview />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.assets.statusPortal()}
          element={
            <PrivateRoute>
              <BoxDetail activeTab={BoxDetailTabs.STATUS} />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.assets.devPortal()}
          element={
            <PrivateRoute>
              <BoxDetail activeTab={BoxDetailTabs.DEVELOPER} />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.assets.sessionsPortal()}
          element={
            <PrivateRoute>
              <BoxDetail activeTab={BoxDetailTabs.SESSIONS} />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.assets.logsPortal()}
          element={
            <PrivateRoute>
              <BoxDetail activeTab={BoxDetailTabs.LOGS} />
            </PrivateRoute>
          }
        />
        {/*this is non MVP and ws refactor brakes this so commenting out for now*/}
        {/*<Route*/}
        {/*  path={appUrls.connectors.detail()}*/}
        {/*  element={*/}
        {/*    <PrivateRoute>*/}
        {/*      <ConnectorDemo />*/}
        {/*    </PrivateRoute>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path={appUrls.sessions.base}
          element={
            <PrivateRoute>
              <SessionsOverview />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.sessions.detail()}
          element={
            <PrivateRoute>
              <SessionDetailOverview />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.users.base}
          element={
            <PrivateRoute>
              <UsersOverview />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.users.new}
          element={
            <PrivateRoute>
              <UserForm />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.users.edit()}
          element={
            <PrivateRoute>
              <UserForm />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.vessels.base}
          element={
            <PrivateRoute>
              <VesselsOverview />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.maintenance.base}
          element={
            <PrivateRoute>
              <MaintenanceOverview />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.maintenance.edit()}
          element={
            <PrivateRoute>
              <TaskEdit activeTab={TaskTabs.EDIT} />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.maintenance.logs()}
          element={
            <PrivateRoute>
              <TaskEdit activeTab={TaskTabs.LOGS} />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.vessels.new}
          element={
            <PrivateRoute>
              <VesselForm />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.vessels.edit()}
          element={
            <PrivateRoute>
              <VesselForm />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.settings.profile}
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path={appUrls.login.landingAfterLogin}
          element={
            <PrivateRoute>
              <LandingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={isSignedIn ? <LandingsPage /> : <LoginPage />}
        />
        <Route path={appUrls.login.base} element={<LoginPage />} />
        <Route
          path={appUrls.login.confirmEmail}
          element={<ConfirmEmailPage />}
        />
        <Route
          path={appUrls.login.register}
          element={<RegisterCognitoPage />}
        />
        <Route
          path={appUrls.login.resetPassword}
          element={<ResetPasswordRequestPage />}
        />
        <Route
          path={appUrls.login.resetPasswordSet()}
          element={<ResetPasswordSetPage />}
        />
        <Route
          path={appUrls.login.resetPasswordCode}
          element={<ResetPasswordCode />}
        />
      </Routes>
    </Router>
  );
};
