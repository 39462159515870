import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../authentication/hooks/useAuth';
import { toasts } from '../../components/feedback/toasts';
import { appUrls } from '../../config/url.constants';
import { getLayoutFlagByKeySelector } from '../../store/selectors/layout.selectors';
import { setLayoutFlag } from '../../store/slices/layout.slice';
import { IAuthHookContext } from '../../types/auth.types';
import { LayoutStateKeys } from '../../types/layout.types';

const MESSAGES = defineMessages({
  emailConfirmed: {
    id: 'confirm_email_page.emailConfirmed',
    defaultMessage: 'Your email was successfully confirmed.',
  },
});

export const ConfirmEmailPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { confirmSignUp } = useAuth() as IAuthHookContext;
  const navigate = useNavigate();
  const intl = useIntl();
  const loading = useSelector(
    getLayoutFlagByKeySelector(LayoutStateKeys.CONFIRM_SIGNUP_DONE),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (err: any) => {
    toasts.error({
      autoHide: true,
      message: err.message,
    });
    navigate(appUrls.base);
  };

  const handleSuccess = () => {
    toasts.success({
      autoHide: true,
      message: intl.formatMessage(MESSAGES.emailConfirmed),
    });
    navigate(appUrls.base);
  };

  useEffect(() => {
    if (params && !loading) {
      if (params.username && params.code) {
        dispatch(
          setLayoutFlag({
            key: LayoutStateKeys.CONFIRM_SIGNUP_DONE,
            flagValue: true,
          }),
        );
        confirmSignUp(params.username, params.code, handleError, handleSuccess);
      }
    }
  }, [params, loading]);

  return <></>;
};
