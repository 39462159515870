import React from 'react';

import { StyledBoxWithPointer } from '../../../../../styled/boxes/StyledBoxWithPointer';
import { IConnectorStatusIconProps } from '../../../../../types/connector.types';
import { getIconSize } from '../../../../../utils/shared/icons/getIconSize';

export const StartConnectorActionIcon = ({
  onClick,
  size,
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBoxWithPointer
    {...space}
    onClick={onClick}
    height={getIconSize(size).height}
  >
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-action-reset"
    >
      <path
        d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H30C31.1046 0.5 32 1.39543 32 2.5V30.5C32 31.6046 31.1046 32.5 30 32.5H2C0.895431 32.5 0 31.6046 0 30.5V2.5Z"
        fill="#02A24F"
      />
      <path
        d="M9 25.5L24.3 16.5L9 7.5V25.5Z"
        stroke="white"
        strokeMiterlimit="10"
      />
    </svg>
  </StyledBoxWithPointer>
);
