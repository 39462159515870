import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Table } from '../../types/table.types';

interface IPayload {
  key: Table;
  value: number;
}
interface IStringArrayPayload {
  key: Table;
  value: string[];
}
interface IPageSizePayload {
  key: Table;
  page: number;
  size: number;
}

type RequestedPage = {
  [key in Table]?: number;
};
type RequestedSize = {
  [key in Table]?: number;
};
type RequestedSort = {
  [key in Table]?: string[];
};

const requestedPage: RequestedPage = {};
const requestedSize: RequestedSize = {};
const requestedSort: RequestedSort = {};

// eslint-disable-next-line no-return-assign
Object.values(Table).forEach((tableName) => (requestedPage[tableName] = 0));
// eslint-disable-next-line no-return-assign
Object.values(Table).forEach((tableName) => (requestedSize[tableName] = 50));
// eslint-disable-next-line no-return-assign
Object.values(Table).forEach((tableName) => (requestedSort[tableName] = undefined));

const initialState = {
  requestedPage,
  requestedSize,
  requestedSort,
};

const tableMetaInformationSlice = createSlice({
  name: 'tableMetaInfo',
  initialState,
  reducers: {
    updateRequestedTablePage(state, action: PayloadAction<IPayload>) {
      // console.log('updateRequestedTablePage', action.payload);

      const { key, value } = action.payload;
      return {
        ...state,
        requestedPage: {
          ...state.requestedPage,
          [key]: value,
        },
      };
    },
    updateRequestedTablePageSize(state, action: PayloadAction<IPageSizePayload>) {
      // console.log('updateRequestedTablePageSize', action.payload);

      const { key, page, size } = action.payload;
      return {
        ...state,
        requestedSize: {
          ...state.requestedSize,
          [key]: size,
        },
        requestedPage: {
          ...state.requestedPage,
          [key]: page,
        },
      };
    },
    updateRequestedTableSize(state, action: PayloadAction<IPayload>) {
      // console.log('updateRequestedTableSize', action.payload);

      const { key, value } = action.payload;
      return {
        ...state,
        requestedSize: {
          ...state.requestedSize,
          [key]: value,
        },
      };
    },
    updateRequestedTableSort(state, action: PayloadAction<IStringArrayPayload>) {
      // console.log('updateRequestedTableSort', action.payload);

      const { key, value } = action.payload;
      return {
        ...state,
        requestedSort: {
          ...state.requestedSort,
          [key]: value,
        },
      };
    },
  },
});

export const {
  updateRequestedTablePage,
  updateRequestedTablePageSize,
  updateRequestedTableSize,
  updateRequestedTableSort
} = tableMetaInformationSlice.actions;
export default tableMetaInformationSlice.reducer;
