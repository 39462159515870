import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { IAssetCommand } from '../../types/asset.types';
import { baseApi, RtkTagType } from './base.api';

export const assetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    postAssetCommand: builder.mutation<
      QueryReturnValue,
      { assetId: string; command: IAssetCommand }
    >({
      query: ({ assetId, command }) => ({
        url: `/assets/${assetId}/commands`,
        method: 'POST',
        body: command,
      }),
      invalidatesTags: (result) => (result ? [{ type: RtkTagType.TAGS }] : []),
    }),
  }),
});

export const { usePostAssetCommandMutation } = assetsApi;
