import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AppLayout } from '../../../AppLayout';
import {
  currentUserSelector,
  hasCurrentUserPermissionSelector,
} from '../../../store/selectors/user.selectors';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { theme } from '../../../theme';
import { UserPermission } from '../../../types/user.types';
import { VesselsTableWithFilter } from '../VesselsTable/VesselsTableWithFilter';

const MESSAGES = defineMessages({
  heading: {
    id: 'vessels_overview.heading',
    defaultMessage: 'Vessels Overview',
  },
});

export const VesselsOverview = () => {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);
  const canManageVessels = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_VESSELS),
  );

  return (
    <AppLayout isAllowed={canManageVessels}>
      <StyledBox p={theme.spacing.large}>
        <StyledHeading variant="h1">
          {intl.formatMessage(MESSAGES.heading)}
        </StyledHeading>
        {currentUser && <VesselsTableWithFilter currentUser={currentUser} />}
      </StyledBox>
    </AppLayout>
  );
};
