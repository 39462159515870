import { SpaceProps } from 'styled-system';

import { IConnectorBox, ResourceType } from './connector.types';
import { IconSize } from './icon.types';
import { ITagStatus } from './tag.types';
import { IPagedResponse } from './api.types';

export enum BoxType {
  ELECTRICITY = 'ELECTRICITY',
  WATER = 'WATER',
  HYBRID = 'HYBRID',
  UNKNOWN = 'UNKNOWN',
  WASTE = 'WASTE',
  ELECTRICITY_WASTE = 'ELECTRICITY_WASTE',
  WATER_WASTE = 'WATER_WASTE',
}

export enum BoxesFilter {
  ASSET_ID = 'ASSET ID',
  TYPE_FILTER = 'TYPE FILTER',
  LOCATION = 'LOCATION',
  FILTER = 'FILTER',
}

export enum RequestBoxFor {
  ALL = 'all',
  POWER = 'power',
  WATER = 'water',
}

export enum BoxStatus {
  ALARM = 'ALARM',
  OK = 'OK',
  MAINTENANCE = 'MAINTENANCE',
  WARNING = 'WARNING',
  UNKNOWN = 'UNKNOWN',
}

export enum BoxTechStatus {
  ALARM = 'ALARM',
  OK = 'OK',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  WARNING = 'WARNING',
  UNKNOWN = 'UNKNOWN',
}

export interface IAssetLocation {
  longitude: string;
  latitude: string;
}

export interface IAssetInfo {
  id: string;
  connectors: IConnectorBox[];
  label: string;
  location?: IAssetLocation;
  tagStatuses: ITagStatus[];
}

export type IAssetResponse = IPagedResponse<IAssetInfo>;

export interface IAssetStatusIconProps extends SpaceProps {
  boxTechStatus: BoxTechStatus;
  size: IconSize;
}

export interface IAssetForNewSession {
  id: string;
  label: string;
}

export interface IAssetForSearchSession extends IAssetForNewSession {
  operatorName: string;
}

export interface IAssetesForNewSessionParams {
  operatorId: string;
  resourceType: ResourceType;
}
