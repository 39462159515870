import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AppLayout } from '../../../AppLayout';
import { useGetUsersQuery } from '../../../store/api/users.api';
import {
  currentUserSelector,
  hasCurrentUserPermissionSelector,
} from '../../../store/selectors/user.selectors';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { theme } from '../../../theme';
import { UserPermission } from '../../../types/user.types';
import { UsersTableWithFilter } from '../UsersTable/UsersTableWithFilter';

const MESSAGES = defineMessages({
  heading: {
    id: 'users_overview.heading',
    defaultMessage: 'Users Overview',
  },
});

export const UsersOverview = () => {
  const intl = useIntl();

  useGetUsersQuery();

  const canManageUsers = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_USERS),
  );

  const currentUser = useSelector(currentUserSelector);

  return (
    <AppLayout isAllowed={canManageUsers}>
      <StyledBox p={theme.spacing.large}>
        <StyledHeading variant="h1">
          {intl.formatMessage(MESSAGES.heading)}
        </StyledHeading>
        {currentUser && <UsersTableWithFilter currentUser={currentUser} />}
      </StyledBox>
    </AppLayout>
  );
};
