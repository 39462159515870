import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { AppLayout } from '../../../AppLayout';
import { TopBar } from '../../../components/navigation/detail/TopBar';
import { appUrls } from '../../../config/url.constants';
import { useGetAuthorizedBoxByIdQuery } from '../../../store/api/boxes.api';
import { hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { theme } from '../../../theme';
import { BoxDetailTabs } from '../../../types/tab.types';
import { UserPermission } from '../../../types/user.types';
import { BoxDeveloperPortal } from './BoxDeveloperPortal/BoxDeveloperPortal';
import { BoxSessionsPortal } from './BoxSessionsPortal/BoxSessionsPortal';
import { BoxStatusPortal } from './BoxStatusPortal/BoxStatusPortal';
import { AssetLogs } from './AssetLogs/AssetLogs';

interface IProps {
  activeTab: BoxDetailTabs;
}

export const BoxDetail = ({ activeTab }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { assetId } = useParams() as { assetId: string };
  const { data: boxInfo } = useGetAuthorizedBoxByIdQuery(assetId);
  const [currentTab, setCurrentTab] = useState(activeTab);
  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );
  const devRoute = appUrls.assets.devPortal(assetId);
  const sessionsRoute = appUrls.assets.sessionsPortal(assetId);
  const statusRoute = appUrls.assets.statusPortal(assetId);
  const logsRoute = appUrls.assets.logsPortal(assetId);

  const matchDev = useMatch(devRoute);
  const matchSessions = useMatch(sessionsRoute);
  const matchStatus = useMatch(statusRoute);
  const matchLogs = useMatch(logsRoute)

  useEffect(() => {
    if (matchDev) {
      setCurrentTab(BoxDetailTabs.DEVELOPER);
    }
    if (matchSessions) {
      setCurrentTab(BoxDetailTabs.SESSIONS);
    }
    if (matchStatus) {
      setCurrentTab(BoxDetailTabs.STATUS);
    }
    if (matchLogs) {
      setCurrentTab(BoxDetailTabs.LOGS);
    }
  }, [matchDev, matchSessions, matchStatus, matchLogs, location]);

  const handleTabClick = (tabPane: BoxDetailTabs) => {
    if (tabPane === BoxDetailTabs.DEVELOPER) {
      navigate(devRoute);
    }
    if (tabPane === BoxDetailTabs.SESSIONS) {
      navigate(sessionsRoute);
    }
    if (tabPane === BoxDetailTabs.STATUS) {
      navigate(statusRoute);
    }
    if (tabPane === BoxDetailTabs.LOGS) {
      navigate(logsRoute);
    }
  };

  return (
    <AppLayout isAllowed={canManageAssets}>
      <StyledBox p={theme.spacing.large}>
        <StyledHeading variant="h1">{boxInfo?.label}</StyledHeading>
        <TopBar selectedTab={currentTab} onTabClick={handleTabClick} />
        {matchStatus && <BoxStatusPortal boxInfo={boxInfo} />}
        {matchSessions && <BoxSessionsPortal boxId={boxInfo?.id} />}
        {matchDev && <BoxDeveloperPortal boxInfo={boxInfo} />}
        {matchLogs && boxInfo?.id && <AssetLogs boxInfo={boxInfo} />}
      </StyledBox>
    </AppLayout>
  );
};
