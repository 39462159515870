import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { appUrls } from '../../../config/url.constants';
import { useGetSessionsQuery } from '../../../store/api/sessions.api';
import {
  sessionFilterByKeySelector,
  sessionFiltersByKeysSelector,
} from '../../../store/selectors/filters.selectors';
import { sessionsDataSelector } from '../../../store/selectors/session.selectors';
import { requestedTablePageSelector } from '../../../store/selectors/tableMetaInfo.selectors';
import { hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { setSessionFilter } from '../../../store/slices/filters.slice';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { theme } from '../../../theme';
import {
  IPagedSessionFilters,
  ISessionFilters,
  SessionFilterKey,
} from '../../../types/filters.types';
import { RequestSessionFor, SessionFilter } from '../../../types/session.types';
import { Table } from '../../../types/table.types';
import { ICurrentUser, UserPermission } from '../../../types/user.types';
import { getRequestSessionFor } from '../../../utils/sessions/getRequestSessionFor';
import { SessionFormWithModal } from '../SessionForm/SessionFormWithModal';
import { SessionsTable } from './SessionsTable';
import { SessionsTableFilter } from './SessionsTableFilter';

const MESSAGES = defineMessages({
  createSession: {
    id: 'sessions_table_with_filter.createSession',
    defaultMessage: 'Start new session',
  },
  [SessionFilter.BOX]: {
    id: 'sessions_table_with_filter.box',
    defaultMessage: 'Box',
  },
  [SessionFilter.ENI_NUMBER]: {
    id: 'sessions_table_with_filter.eniNumber',
    defaultMessage: 'E.N.I',
  },
  search: {
    id: 'sessions_table_with_filter.search',
    defaultMessage: 'Search',
  },
  [SessionFilter.TRANSACTION_ID]: {
    id: 'sessions_table_with_filter.transactionId',
    defaultMessage: 'Transaction ID',
  },
  [SessionFilter.VESSEL]: {
    id: 'sessions_table_with_filter.vessel',
    defaultMessage: 'Vessel',
  },
  [SessionFilter.INITIATOR]: {
    id: 'sessions_table_with_filter.initiator',
    defaultMessage: 'Initiator',
  },
});

interface IProps {
  boxId?: string;
  currentUser?: ICurrentUser;
}

export const SessionsTableWithFilter = ({ boxId, currentUser }: IProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentRoute = useLocation();
  const [showModal, setShowModal] = useState(false);

  const requestedSessionTablePage = useSelector(
    requestedTablePageSelector(Table.SESSIONS_TABLE),
  );
  const canCreateSession = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_SESSION),
  );
  const requestSessionFor = useSelector(
    sessionFilterByKeySelector(SessionFilterKey.for),
  ) as RequestSessionFor | undefined;

  const currentRouteIsSessionPortal =
    currentRoute.pathname.includes(appUrls.assets.base) &&
    currentRoute.pathname.includes(appUrls.sessions.base);

  useEffect(() => {
    const currentRouteState = (currentRoute as any).state as {
      createNewSession?: boolean;
    };
    setShowModal((prev) => currentRouteState?.createNewSession || prev);
  }, [currentRoute]);

  // store the boxId in the redux store to make sure only the sessions for the given boxId are returned
  // from the BE
  useEffect(() => {
    if (boxId) {
      dispatch(
        setSessionFilter({
          key: SessionFilterKey.boxId,
          value: boxId,
        }),
      );
    }
  }, [boxId]);

  useEffect(() => {
    if (currentUser) {
      dispatch(
        setSessionFilter({
          key: SessionFilterKey.for,
          value: getRequestSessionFor(currentUser),
        }),
      );
    }
  }, [currentUser]);

  const sessionFilterKeys = [
    SessionFilterKey.boxId,
    SessionFilterKey.eniNumber,
    SessionFilterKey.transactionId,
    SessionFilterKey.vesselName,
    SessionFilterKey.initiatorName,
  ];

  const sessionFilterValues = useSelector(
    sessionFiltersByKeysSelector(sessionFilterKeys),
  ) as ISessionFilters | undefined;

  const requestedSessionsFilters: IPagedSessionFilters = {
    ...sessionFilterValues,
    for: requestSessionFor,
    page: requestedSessionTablePage,
    sort: ['active,DESC', 'startedAt,DESC'],
  };

  useGetSessionsQuery(requestedSessionsFilters, {
    skip: !requestSessionFor,
  });
  const sessionData = useSelector(
    sessionsDataSelector(requestedSessionsFilters),
  );

  const handleAddNewSessionClick = () => setShowModal(true);

  const handleCancelForm = () => setShowModal(false);

  return (
    <>
      <StyledBox mb={theme.spacing.large}>
        <SessionsTableFilter />
      </StyledBox>
      {canCreateSession && !currentRouteIsSessionPortal && (
        <StyledButton
          variant="primary"
          mt={theme.spacing.large}
          mb={theme.spacing.large}
          onClick={handleAddNewSessionClick}
          data-testid="btn-create-session"
        >
          <PlusOutlined />
          {intl.formatMessage(MESSAGES.createSession)}
        </StyledButton>
      )}
      {showModal && (
        <SessionFormWithModal
          showModal={showModal}
          onCancelForm={handleCancelForm}
        />
      )}
      {sessionData && (
        <PrimaryWrapperBox>
          <SessionsTable
            boxId={boxId}
            sessionData={sessionData}
            requestedSessionFilters={requestedSessionsFilters}
          />
        </PrimaryWrapperBox>
      )}
    </>
  );
};
