import {
  ClockCircleOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  SettingOutlined,
  UserOutlined,
  DashboardOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { Layout, Menu, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../authentication/hooks/useAuth';
import { appUrls } from '../../../config/url.constants';
import {
  currentUserSelector,
  hasCurrentUserPermissionSelector,
} from '../../../store/selectors/user.selectors';
import { FlexBox } from '../../../styled/flex/FlexBox';
import { FlexCentered } from '../../../styled/flex/FlexCentered';
import { StyledSpan } from '../../../styled/text/StyledSpan';
import { StyledSubTextLight } from '../../../styled/text/Text';
import { colors, theme } from '../../../theme';
import { IAuthHookContext } from '../../../types/auth.types';
import { NavLinks } from '../../../types/navigation.types';
import { UserPermission } from '../../../types/user.types';
import { getInitialNavLink } from '../../../utils/navigation/getInitialNavLink';
import { formatUserRole } from '../../../utils/users/formatUserRole';
import { LogoWithLabel } from '../../brand/LogoWithLabel';
import { VesselIcon } from '../../vessels/icons/VesselIcon';

const MESSAGES = defineMessages({
  dashboardNavLink: {
    id: 'side_navigation_bar.dashboardLink',
    defaultMessage: 'Dashboard',
  },
  listNavLink: {
    id: 'side_navigation_bar.listNavigationLink',
    defaultMessage: 'Assets',
  },
  sessionsNavLink: {
    id: 'side_navigation_bar.sessionsNavigationLink',
    defaultMessage: 'Sessions',
  },
  usersNavLink: {
    id: 'side_navigation_bar.usersNavigationLink',
    defaultMessage: 'Users',
  },
  vesselsNavLink: {
    id: 'side_navigation_bar.vesselsNavigationLink',
    defaultMessage: 'Vessels',
  },
  maintenanceNavLink: {
    id: 'side_navigation_bar.maintenanceNavLink',
    defaultMessage: 'Maintenance',
  },
  settingsNavLink: {
    id: 'side_navigation_bar.settingsNavigationLink',
    defaultMessage: 'Settings',
  },
  confirmLogOutTitle: {
    id: 'side_navigation_bar.confirmLogOutTitle',
    defaultMessage: 'Are you sure you want to log out?',
  },
  confirmLogOutMessage: {
    id: 'side_navigation_bar.confirmLogOutMessage',
    defaultMessage: 'Logging out will redirect you to the login screen.',
  },
  confirmLogOutConfirmButton: {
    id: 'side_navigation_bar.confirmLogOutConfirmButton',
    defaultMessage: 'Log out',
  },
});

interface IProps {
  isCollapsed: boolean;
  onSidebarClick: () => void;
}

const { Sider } = Layout;
const { Item } = Menu;

const StyledSider = styled(Sider)({
  borderTopRightRadius: theme.spacing.xxLarge,
  borderBottomRightRadius: theme.spacing.xxLarge,
  marginRight: theme.spacing.xxLarge,
  background: colors.white.primary,
  height: '100%',
  position: 'fixed',
  overflow: 'auto',

  '.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected': {
    backgroundColor: 'transparent',
  },

  '.ant-menu-item-selected': {
    color: colors.green.primary,
  },

  '.ant-menu-light .ant-menu-item:hover': {
    color: `${colors.white.primary}`,
    backgroundColor: `${colors.green.background}`,
  },
});

const StyledMenu = styled(Menu)({
  marginTop: theme.spacing.xxLarge,
});

interface StyledMenuItemProps {
  isActive?: boolean;
  isCollapsed?: boolean;
}

const StyledMenuItem = styled(Item, {
  shouldForwardProp: (props) => props !== 'isActive' && props !== 'isCollapsed',
})(({ isActive, isCollapsed }: StyledMenuItemProps) => ({
  display: !isCollapsed ? 'flex' : '',
  alignItems: !isCollapsed ? 'center' : '',

  svg: {
    stroke: !isActive ? colors.black.primary : colors.green.primary,
    width: !isCollapsed ? '14px' : '16px',
    height: !isCollapsed ? '14px' : '16px',
  },

  '&:hover': {
    color: `${colors.white.primary}`,
    backgroundColor: `${colors.green.background}`,

    svg: {
      stroke: colors.white.primary,
    },
  },

  '&&& .ant-menu-title-content': {
    marginLeft: '10px',
  },
}));

export const SideNavBar = ({ isCollapsed, onSidebarClick }: IProps) => {
  const intl = useIntl();
  const currentRoute = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth() as IAuthHookContext;

  const currentUser = useSelector(currentUserSelector);
  const canCreateSession = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_SESSION),
  );
  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );
  const canManageCompanySessions = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_COMPANY_SESSIONS),
  );
  const canManageVessels = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_VESSELS),
  );
  const canManageUsers = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_USERS),
  );
  const [selectedNavLink, setSelectedNavLink] = useState(
    getInitialNavLink(currentUser),
  );

  useEffect(() => {
    if (currentRoute.pathname.includes(appUrls.maintenance.base)) {
      setSelectedNavLink([NavLinks.MAINTENANCE]);
    }
    if (currentRoute.pathname.includes(appUrls.dashboard.base)) {
      setSelectedNavLink([NavLinks.DASHBOARD]);
    }
    if (currentRoute.pathname.includes(appUrls.assets.base)) {
      setSelectedNavLink([NavLinks.LIST]);
    }
    // sessions displayed in the session's portal should result in the list link to be active
    // and not the sessions link
    if (
      currentRoute.pathname.includes(appUrls.sessions.base) &&
      !currentRoute.pathname.includes(appUrls.assets.base)
    ) {
      setSelectedNavLink([NavLinks.SESSIONS]);
    }
    if (currentRoute.pathname.includes(appUrls.users.base)) {
      setSelectedNavLink([NavLinks.USERS]);
    }
    if (currentRoute.pathname.includes(appUrls.vessels.base)) {
      setSelectedNavLink([NavLinks.VESSELS]);
    }
    if (currentRoute.pathname.includes(appUrls.settings.base)) {
      setSelectedNavLink([NavLinks.SETTINGS]);
    }
  }, [currentRoute]);

  const handleNavLinkClick = (navLink: NavLinks) => {
    setSelectedNavLink([navLink]);

    if (navLink === NavLinks.MAINTENANCE) {
      navigate(appUrls.maintenance.base);
    }
    if (navLink === NavLinks.DASHBOARD) {
      navigate(appUrls.dashboard.base);
    }
    if (navLink === NavLinks.LIST) {
      navigate(appUrls.assets.base);
    }
    if (navLink === NavLinks.SESSIONS) {
      navigate(appUrls.sessions.base);
    }
    if (navLink === NavLinks.USERS) {
      navigate(appUrls.users.base);
    }
    if (navLink === NavLinks.VESSELS) {
      navigate(appUrls.vessels.base);
    }
    if (navLink === NavLinks.SETTINGS) {
      navigate(appUrls.settings.profile);
    }
  };

  const getLogoutIcon = () => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()} data-testid="btn-logout">
      <Popconfirm
        title={intl.formatMessage(MESSAGES.confirmLogOutTitle)}
        okText={intl.formatMessage(MESSAGES.confirmLogOutConfirmButton)}
        onConfirm={signOut}
        placement="right"
      >
        <LogoutOutlined />
      </Popconfirm>
    </div>
  );

  return (
    <StyledSider
      collapsed={isCollapsed}
      collapsible
      onClick={onSidebarClick}
      trigger={null}
    >
      <LogoWithLabel
        showLabel={!isCollapsed}
        onClick={() => navigate(appUrls.dashboard.base)}
      />
      <FlexBox
        flexDirection="column"
        height="90%"
        justifyContent="space-between"
      >
        <StyledMenu
          defaultSelectedKeys={selectedNavLink}
          selectedKeys={selectedNavLink}
        >
          {canManageAssets && <Item
            key={NavLinks.DASHBOARD}
            icon={<DashboardOutlined />}
            onClick={() => handleNavLinkClick(NavLinks.DASHBOARD)}
          >
            {intl.formatMessage(MESSAGES.dashboardNavLink)}
          </Item>}
          {canManageAssets && (
            <Item
              key={NavLinks.LIST}
              icon={<OrderedListOutlined />}
              onClick={() => handleNavLinkClick(NavLinks.LIST)}
            >
              {intl.formatMessage(MESSAGES.listNavLink)}
            </Item>
          )}
          {(canManageAssets ||
            canManageCompanySessions ||
            canCreateSession) && (
            <Item
              key={NavLinks.SESSIONS}
              icon={<ClockCircleOutlined />}
              onClick={() => handleNavLinkClick(NavLinks.SESSIONS)}
            >
              {intl.formatMessage(MESSAGES.sessionsNavLink)}
            </Item>
          )}
          {canManageVessels && (
            <StyledMenuItem
              key={NavLinks.VESSELS}
              icon={<VesselIcon />}
              isActive={selectedNavLink.includes(NavLinks.VESSELS)}
              isCollapsed={isCollapsed}
              onClick={() => handleNavLinkClick(NavLinks.VESSELS)}
            >
              {!isCollapsed && intl.formatMessage(MESSAGES.vesselsNavLink)}
            </StyledMenuItem>
          )}
          {canManageAssets && <Item
            key={NavLinks.MAINTENANCE}
            icon={<IssuesCloseOutlined />}
            onClick={() => handleNavLinkClick(NavLinks.MAINTENANCE)}
          >
            {intl.formatMessage(MESSAGES.maintenanceNavLink)}
          </Item>}
          {canManageUsers && (
            <Item
              key={NavLinks.USERS}
              icon={<UserOutlined />}
              onClick={() => handleNavLinkClick(NavLinks.USERS)}
            >
              {intl.formatMessage(MESSAGES.usersNavLink)}
            </Item>
          )}
          <Item
            key={NavLinks.SETTINGS}
            icon={<SettingOutlined />}
            onClick={() => handleNavLinkClick(NavLinks.SETTINGS)}
          >
            {intl.formatMessage(MESSAGES.settingsNavLink)}
          </Item>
        </StyledMenu>
        {!isCollapsed && currentUser ? (
          <FlexBox
            flexDirection="column"
            pl={theme.spacing.medium}
            pr={theme.spacing.xLarge}
          >
            <FlexBox alignItems="center" justifyContent="space-between">
              <StyledSpan
                mr={theme.spacing.small}
                data-testid="current-user-name"
              >
                {`${currentUser.firstName} ${currentUser.lastName}`}
              </StyledSpan>
              {getLogoutIcon()}
            </FlexBox>
            <StyledSubTextLight data-testid="current-user-role">
              {formatUserRole(currentUser.role)}
            </StyledSubTextLight>
          </FlexBox>
        ) : (
          <FlexCentered>{getLogoutIcon()}</FlexCentered>
        )}
      </FlexBox>
    </StyledSider>
  );
};
