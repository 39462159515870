import { Form, Select } from 'antd';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { appUrls } from '../../../config/url.constants';
import { useGetBoxesForSearchSessionQuery } from '../../../store/api/boxes.api';
import { boxesForSearchSessionSelector } from '../../../store/selectors/box.selectors';
import { sessionFilterByKeySelector } from '../../../store/selectors/filters.selectors';
import { setSessionFilter } from '../../../store/slices/filters.slice';
import { updateRequestedTablePage } from '../../../store/slices/tableMetaInfo.slice';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledFormItem } from '../../../styled/form/StyledFormItem';
import { StyledSearch } from '../../../styled/form/StyledSearch';
import { StyledSelect } from '../../../styled/form/StyledSelect';
import { theme } from '../../../theme';
import { FilterKey, SessionFilterKey } from '../../../types/filters.types';
import { SessionFilter } from '../../../types/session.types';
import { Table } from '../../../types/table.types';
import { FilterElements } from '../../../components/filters/FilterElements';

const { Option } = Select;

const MESSAGES = defineMessages({
  search: {
    id: 'sessions_table_filter.search',
    defaultMessage: 'Search',
  },
  boxDropdownPlaceholder: {
    id: 'sessions_table_filter.boxDropdownPlaceholder',
    defaultMessage: 'Select a box',
  },
  [SessionFilter.BOX]: {
    id: 'sessions_table_filter.box',
    defaultMessage: 'Box',
  },
  [SessionFilter.ENI_NUMBER]: {
    id: 'sessions_table_filter.eniNumber',
    defaultMessage: 'E.N.I',
  },
  [SessionFilter.TRANSACTION_ID]: {
    id: 'sessions_table_filter.transactionId',
    defaultMessage: 'Transaction ID',
  },
  [SessionFilter.VESSEL]: {
    id: 'sessions_table_filter.vessel',
    defaultMessage: 'Vessel',
  },
  [SessionFilter.INITIATOR]: {
    id: 'sessions_table_filter.initiator',
    defaultMessage: 'Initiator',
  },
});

export const SessionsTableFilter = () => {
  const intl = useIntl();
  const currentRoute = useLocation();
  const { assetId } = useParams<{ assetId?: string }>();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // clear boxId in store because if user comes from box session portal
  // it otherwise shows that boxId
  useEffect(() => {
    dispatch(
      setSessionFilter({
        key: SessionFilterKey.boxId,
        value: '',
      }),
    );
  }, []);

  useGetBoxesForSearchSessionQuery();
  const boxesForSearchSession = useSelector(boxesForSearchSessionSelector);

  const boxIdFilterValue = useSelector(
    sessionFilterByKeySelector(SessionFilterKey.boxId),
  );
  const eniNumberFilterValue = useSelector(
    sessionFilterByKeySelector(SessionFilterKey.eniNumber),
  );
  const transactionIdFilterValue = useSelector(
    sessionFilterByKeySelector(SessionFilterKey.transactionId),
  );
  const vesselNameFilterValue = useSelector(
    sessionFilterByKeySelector(SessionFilterKey.vesselName),
  );
  const initiatorNameFilterValue = useSelector(
    sessionFilterByKeySelector(SessionFilterKey.initiatorName),
  );

  const currentRouteIsSessionPortal =
    currentRoute.pathname.includes(appUrls.assets.base) &&
    currentRoute.pathname.includes(appUrls.sessions.base);

  const handleChangeFilter = (key: FilterKey, value: string | number) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.SESSIONS_TABLE,
        value: 0,
      }),
    );
    dispatch(
      setSessionFilter({
        key,
        value,
      }),
    );
  };

  const currentBoxLabel: string | undefined =
    (currentRouteIsSessionPortal &&
      boxesForSearchSession &&
      boxesForSearchSession.find((box) => box.id === assetId)?.label) ||
    '';

  return (
    <PrimaryWrapperBox mb={theme.spacing.large}>
      <Form form={form} layout="inline">
      
        <FilterElements title={intl.formatMessage(MESSAGES[SessionFilter.BOX])}>
        <StyledSelect
            value={currentBoxLabel || boxIdFilterValue}
            showSearch
            allowClear
            disabled={currentRouteIsSessionPortal}
            minWidth="213px"
            onChange={(value) =>
              handleChangeFilter(SessionFilterKey.boxId, value as string)
            }
            placeholder={intl.formatMessage(MESSAGES.boxDropdownPlaceholder)}
          >
            {boxesForSearchSession?.map((boxIdLabelOperator) => (
              <Option key={boxIdLabelOperator.id} value={boxIdLabelOperator.id}>
                {`${boxIdLabelOperator.label} | ${boxIdLabelOperator.operatorName}`}
              </Option>
            ))}
          </StyledSelect>
        </FilterElements>
        

        <FilterElements title={intl.formatMessage(MESSAGES[SessionFilter.ENI_NUMBER])}>
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(SessionFilterKey.eniNumber, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(SessionFilterKey.eniNumber, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={eniNumberFilterValue}
            data-testid={`search-${SessionFilterKey.eniNumber}`}
          />
        </FilterElements>                

        
        <FilterElements title={intl.formatMessage(MESSAGES[SessionFilter.TRANSACTION_ID])}>
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(SessionFilterKey.transactionId, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(SessionFilterKey.transactionId, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={transactionIdFilterValue}
            data-testid={`search-${SessionFilterKey.transactionId}`}
          />
        </FilterElements>

        <FilterElements title={intl.formatMessage(MESSAGES[SessionFilter.VESSEL])}>
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(SessionFilterKey.vesselName, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(SessionFilterKey.vesselName, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={vesselNameFilterValue}
            data-testid={`search-${SessionFilterKey.vesselName}`}
          />
        </FilterElements>

        
        <FilterElements title={intl.formatMessage(MESSAGES[SessionFilter.INITIATOR])}>
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(SessionFilterKey.initiatorName, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(SessionFilterKey.initiatorName, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={initiatorNameFilterValue}
            data-testid={`search-${SessionFilterKey.initiatorName}`}
          />
        </FilterElements>
      </Form>
    </PrimaryWrapperBox>
  );
};
