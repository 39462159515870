import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AppLayout } from '../../../AppLayout';
import { hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { UserPermission } from '../../../types/user.types';
import { BoxesTable } from '../BoxesTable/BoxesTable';
import { BoxesTableFilter } from '../BoxesTable/BoxesTableFilter';
import { BoxesFilterMobile } from '../BoxesTable/BoxesMobile/BoxesFilterMobile';

const MESSAGES = defineMessages({
  heading: {
    id: 'boxes_overview.heading',
    defaultMessage: 'Assets list',
  },
});

export const BoxesOverview = () => {
  const intl = useIntl();
  const [isMobile] = useState(window.innerWidth < 768)

  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );

  return (
    <AppLayout isAllowed={canManageAssets}>
      <StyledBox p="20px">
        <StyledHeading variant="h1">
          {intl.formatMessage(MESSAGES.heading)}
        </StyledHeading>
        {isMobile ? <BoxesFilterMobile /> : <BoxesTableFilter />}
        <PrimaryWrapperBox>
          <BoxesTable />
        </PrimaryWrapperBox>
      </StyledBox>
    </AppLayout>
  );
};
