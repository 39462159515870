export interface ILayoutFlagPayload {
  key: LayoutStateKeys | string;
  flagValue: boolean;
}

export interface ILayoutFlags {
  [key: string]: boolean;
}

export interface IIntlMessage {
  id: string;
  defaultMessage: string | null;
}

export interface IIntlErrorMessage {
  message: IIntlMessage | null;
  description?: string | null;
}

export interface ILayoutMessages {
  success?: IIntlMessage | null;
  error?: IIntlErrorMessage | null;
}

export enum LayoutStateKeys {
  ASSET_ACTION_TOGGLE_LOADING = 'ASSET_ACTION_TOGGLE_LOADING',
  CONFIRM_SIGNUP_DONE = 'CONFIRM_SIGNUP_DONE',
}
