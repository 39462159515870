import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { FlexCentered } from '../../styled/flex/FlexCentered';
import { StyledDiv } from '../../styled/StyledDiv';
import { theme } from '../../theme';
import { BoxTechStatus, BoxType } from '../../types/box.types';
import { IconSize } from '../../types/icon.types';
import { ElectricityBoxIcon } from './icons/powerSource/ElectricityBoxIcon';
import { HybridBoxIcon } from './icons/powerSource/HybridBoxIcon';
import { WaterBoxIcon } from './icons/powerSource/WaterBoxIcon';

const MESSAGES = defineMessages({
  powerBox: {
    id: 'box_type_icon.powerBox',
    defaultMessage: 'Power box',
  },
  waterBox: {
    id: 'box_type_icon.waterBox',
    defaultMessage: 'Water box',
  },
  hybridBox: {
    id: 'box_type_icon.hybridBox',
    defaultMessage: 'Hybrid box',
  },
});

interface IProps {
  boxType: BoxType;
  boxTechStatus: BoxTechStatus;
  showText?: boolean;
}

export const BoxTypeIcon = ({
  boxTechStatus,
  boxType,
  showText = true,
}: IProps) => {
  const intl = useIntl();

  const getIconAndLabel = () => {
    if (boxType === BoxType.ELECTRICITY) {
      return (
        <>
          <ElectricityBoxIcon
            boxTechStatus={boxTechStatus}
            size={IconSize.XXLARGE}
          />
          {showText && (
            <StyledDiv ml={theme.spacing.small}>
              {intl.formatMessage(MESSAGES.powerBox)}
            </StyledDiv>
          )}
        </>
      );
    }
    if (boxType === BoxType.WATER) {
      return (
        <>
          <WaterBoxIcon boxTechStatus={boxTechStatus} size={IconSize.XXLARGE} />
          {showText && (
            <StyledDiv ml={theme.spacing.small}>
              {intl.formatMessage(MESSAGES.waterBox)}
            </StyledDiv>
          )}
        </>
      );
    }
    if (boxType === BoxType.HYBRID) {
      return (
        <>
          <HybridBoxIcon
            boxTechStatus={boxTechStatus}
            size={IconSize.XXLARGE}
          />
          {showText && (
            <StyledDiv ml={theme.spacing.small}>
              {intl.formatMessage(MESSAGES.hybridBox)}
            </StyledDiv>
          )}
        </>
      );
    }
    return null;
  };
  return <FlexCentered>{getIconAndLabel()}</FlexCentered>;
};
