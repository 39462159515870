import { createSelector } from '@reduxjs/toolkit';

import {
  BoxStatus,
  IAssetesForNewSessionParams,
  IAssetForNewSession,
  IAssetForSearchSession,
} from '../../types/box.types';
import { BoxTag } from '../../types/tag.types';
import { boxesApi } from '../api/boxes.api';
// eslint-disable-next-line import/no-cycle
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from './tags.selectors';
import { IAssetFilters } from '../../types/filters.types';

export const boxesForNewSessionRtkState = (
  boxesForNewSessionParams: IAssetesForNewSessionParams,
) => boxesApi.endpoints.getBoxesForNewSession.select(boxesForNewSessionParams);

export const boxesForSearchSessionRtkState =
  boxesApi.endpoints.getBoxesForSearchSession.select();

export const authorizedBoxesRtkState = (requestedAssetFilters: IAssetFilters) => {
  return boxesApi.endpoints.getAuthorizedBoxes.select(requestedAssetFilters);
}

export const authorizedBoxesSelector = (requestedAssetFilters: IAssetFilters) =>
  createSelector(
    authorizedBoxesRtkState(requestedAssetFilters),
    (state) => {
      return state.data;
    },
  );


export const BoxDataSelector = (requestAssetFilters: IAssetFilters) =>
  createSelector(authorizedBoxesRtkState(requestAssetFilters),
  (state) => state.data,
);

export const getBoxByIdRtkState = (id: string) =>
  boxesApi.endpoints.getAuthorizedBoxById.select(id);

export const boxesForNewSessionSelector = (
  boxesForNewSessionParams: IAssetesForNewSessionParams,
) =>
  createSelector(
    boxesForNewSessionRtkState(boxesForNewSessionParams),
    (state): IAssetForNewSession[] | undefined => state.data,
  );

export const boxesForSearchSessionSelector = createSelector(
  boxesForSearchSessionRtkState,
  (state): IAssetForSearchSession[] | undefined => state.data,
);

export const boxByIdSelector = (id: string) =>
  createSelector(getBoxByIdRtkState(id), (state) => state.data);

export const boxStatusSelector = (boxId: string, assetId: string) =>
  createSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      assetId,
      BoxTag.V4_BOX_ALARM,
    ),
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      assetId,
      BoxTag.V3_BOX_WARNING,
    ),
    (alarmTagStatus, warningTagStatus) => {
      const alarm = alarmTagStatus && parseInt(alarmTagStatus?.value, 10);
      const warning = warningTagStatus && parseInt(warningTagStatus?.value, 10);

      if (alarm === 1) {
        return BoxStatus.ALARM;
      }
      if (warning === 1) {
        return BoxStatus.WARNING;
      }
      if (alarm === 0 || warning === 0) {
        return BoxStatus.OK;
      }
      return BoxStatus.UNKNOWN;
    },
  );

export const boxHasWarningOrErrorSelector = (boxId: string, assetId: string) =>
  createSelector(
    boxStatusSelector(boxId, assetId),
    (boxStatus) =>
      boxStatus === BoxStatus.ALARM || boxStatus === BoxStatus.WARNING,
  );
