import { IAssetLogResponse, ITagInfo, ITagStatus } from '../../types/tag.types';
import { wsClient } from '../../utils/ws/WsClient';
import { baseApi, RtkTagType } from './base.api';
import { IPagedAssetLogFilters } from '../../types/filters.types';

export const tagsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTagsInfoByAssetId: builder.query<ITagInfo[], string>({
      query: (assetId) => `/assets/${assetId}/tag-info`,
      providesTags: (result, error, id) => [{ type: RtkTagType.TAGS, id }],
    }),

    postAssetLogFiltered: builder.query<
      IAssetLogResponse,
      IPagedAssetLogFilters
    >({
      query: (assetLogFilter) => {
        const { params, ...body } = assetLogFilter;
        return {
          url: '/tag-values',
          method: 'POST',
          headers: [['content-type', 'application/json']],
          body,
          params,
        };
      },
    }),

    tagsChannel: builder.query<{ statuses: ITagStatus[] }, string>({
      queryFn: () => {
        return { data: { statuses: [] } };
      },
      async onCacheEntryAdded(
        assetId,
        { cacheEntryRemoved, updateCachedData },
      ) {
        const tagsSubscription = await wsClient.subscribe(
          `/topic/assets/${assetId}/tags`,
          (newStatus: ITagStatus) => {
            updateCachedData(({ statuses }) => {
              const index = statuses.findIndex(
                (status) => status.tag === newStatus.tag,
              );
              if (index < 0) {
                statuses.push(newStatus);
              } else {
                statuses.splice(index, 1, newStatus);
              }
            });
          },
        );
        await cacheEntryRemoved;
        tagsSubscription.unsubscribe();
      },
    }),
  }),
});

export const {
  useGetTagsInfoByAssetIdQuery,
  usePostAssetLogFilteredQuery,
  useTagsChannelQuery,
} = tagsApi;
